
import {TerraformProvider} from "./terraformSchema";

export const SakuraCloudSchema: TerraformProvider = {
	version: "2.26.0",
    provider: {
        name: "sakuracloud",
		type: "provider",
        displayName: "さくらのクラウド",
        category: "provider",
        schema: {
            arguments: [
                {
                    type: "string",
                    name: "accept_language",
                    description: "The value of AcceptLanguage header used when calling SakuraCloud API. It can also be sourced from the `SAKURACLOUD_ACCEPT_LANGUAGE` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "number",
                    name: "api_request_rate_limit",
                    description: "The maximum number of SakuraCloud API calls per second. It can also be sourced from the `SAKURACLOUD_RATE_LIMIT` environment variables, or via a shared credentials file if `profile` is specified. Default:`10`",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "number",
                    name: "api_request_timeout",
                    description: "The timeout seconds for each SakuraCloud API call. It can also be sourced from the `SAKURACLOUD_API_REQUEST_TIMEOUT` environment variables, or via a shared credentials file if `profile` is specified. Default:`300`",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "api_root_url",
                    description: "The root URL of SakuraCloud API. It can also be sourced from the `SAKURACLOUD_API_ROOT_URL` environment variables, or via a shared credentials file if `profile` is specified. Default:`https://secure.sakura.ad.jp/cloud/zone`",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "default_zone",
                    description: "The name of zone to use as default for global resources. It must be provided, but it can also be sourced from the `SAKURACLOUD_DEFAULT_ZONE` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "fake_mode",
                    description: "The flag to enable fake of SakuraCloud API call. It is for debugging or developing the provider. It can also be sourced from the `FAKE_MODE` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "fake_store_path",
                    description: "The file path used by SakuraCloud API fake driver for storing fake data. It is for debugging or developing the provider. It can also be sourced from the `FAKE_STORE_PATH` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "profile",
                    description: "The profile name of your SakuraCloud account. Default:`default`",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "number",
                    name: "retry_max",
                    description: "The maximum number of API call retries used when SakuraCloud API returns status code `423` or `503`. It can also be sourced from the `SAKURACLOUD_RETRY_MAX` environment variables, or via a shared credentials file if `profile` is specified. Default:`100`",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "number",
                    name: "retry_wait_max",
                    description: "The maximum wait interval(in seconds) for retrying API call used when SakuraCloud API returns status code `423` or `503`.  It can also be sourced from the `SAKURACLOUD_RETRY_WAIT_MAX` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "number",
                    name: "retry_wait_min",
                    description: "The minimum wait interval(in seconds) for retrying API call used when SakuraCloud API returns status code `423` or `503`. It can also be sourced from the `SAKURACLOUD_RETRY_WAIT_MIN` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "secret",
                    description: "The API secret of your SakuraCloud account. It must be provided, but it can also be sourced from the `SAKURACLOUD_ACCESS_TOKEN_SECRET` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "token",
                    description: "The API token of your SakuraCloud account. It must be provided, but it can also be sourced from the `SAKURACLOUD_ACCESS_TOKEN` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "trace",
                    description: "The flag to enable output trace log. It can also be sourced from the `SAKURACLOUD_TRACE` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "string",
                    name: "zone",
                    description: "The name of zone to use as default. It must be provided, but it can also be sourced from the `SAKURACLOUD_ZONE` environment variables, or via a shared credentials file if `profile` is specified",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
                {
                    type: "stringList",
                    name: "zones",
                    description: "A list of available SakuraCloud zone name. It can also be sourced via a shared credentials file if `profile` is specified. Default:[`is1a`, `is1b`, `tk1a`, `tk1v`]",
                    required: false,
                    optional: true,
                    forceNew: false,
                    default: "",
                    maxItems: 0,
                    minItems: 0,
                },
            ],
            attributes: [
            ],
            argumentBlocks: [
            ],
            attributeBlocks: [
            ],
        },
    },
    resources: [
        {
            name: "sakuracloud_apprun_application",
			type: "resource",
            displayName: "AppRunアプリケーション",
            category: "apprun",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "components",
                        description: "The application component information",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "max_scale",
                        description: "The maximum number of scales for the entire application",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "min_scale",
                        description: "The minimum number of scales for the entire application",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of application",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "port",
                        description: "The port number where the application listens for requests",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "timeout_seconds",
                        description: "The time limit between accessing the application's public URL, starting the instance, and receiving a response",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "traffics",
                        description: "The application traffic",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "components",
                        description: "The application component information",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "deploy_source",
                                description: "The sources that make up the component",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "env",
                                description: "The environment variables passed to components",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "max_cpu",
                                description: "The maximum number of CPUs for a component. The values in the list must be in [`0.1`/`0.2`/`0.3`/`0.4`/`0.5`/`0.6`/`0.7`/`0.8`/`0.9`/`1`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "max_memory",
                                description: "The maximum memory of component. The values in the list must be in [`256Mi`/`512Mi`/`1Gi`/`2Gi`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "name",
                                description: "The component name",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "probe",
                                description: "The component probe settings",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "deploy_source",
                        description: "The sources that make up the component",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "container_registry",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "container_registry",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "image",
                                description: "The container image name",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The container registry credentials",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "server",
                                description: "The container registry server name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "username",
                                description: "The container registry credentials",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "env",
                        description: "The environment variables passed to components",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "key",
                                description: "The environment variable name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "environment variable value",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "probe",
                        description: "The component probe settings",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "http_get",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "http_get",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "headers",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path to access HTTP server to check probes",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number for accessing HTTP server and checking probes",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "headers",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The header field name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "The header field value",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "traffics",
                        description: "The application traffic",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "number",
                                name: "percent",
                                description: "The percentage of traffic dispersion",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "version_index",
                                description: "The application version index",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_archive",
			type: "resource",
            displayName: "アーカイブ",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "archive_file",
                        description: "The file path to upload to the SakuraCloud",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the archive. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "hash",
                        description: "The md5 checksum calculated from the base64 encoded file body",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the archive",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the archive. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of archive in GiB. This must be one of [`20`/`40`/`60`/`80`/`100`/`250`/`500`/`750`/`1024`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source_archive_id",
                        description: "The id of the source archive. This conflicts with [`source_disk_id`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source_archive_zone",
                        description: "The share key of source shared archive",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source_disk_id",
                        description: "The id of the source disk. This conflicts with [`source_archive_id`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source_shared_key",
                        description: "The share key of source shared archive",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the archive",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the archive will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_archive_share",
			type: "resource",
            displayName: "アーカイブ共有",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "archive_id",
                        description: "The id of the archive",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the ArchiveShare will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "share_key",
                        description: "The key to use sharing the Archive",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_auto_backup",
			type: "resource",
            displayName: "自動バックアップ",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the AutoBackup. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "disk_id",
                        description: "The disk id to backed up",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the AutoBackup",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "max_backup_num",
                        description: "The number backup files to keep. This must be in the range [`1`-`10`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "1",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the AutoBackup. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the AutoBackup",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "weekdays",
                        description: "A list of weekdays to backed up. The values in the list must be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the AutoBackup will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_auto_scale",
			type: "resource",
            displayName: "オートスケール",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "api_key_id",
                        description: "The id of the API key",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "config",
                        description: "The configuration file for sacloud/autoscaler",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "cpu_threshold_scaling",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the AutoScale. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "disabled",
                        description: "The flag to stop trigger",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the AutoScale",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the AutoScale. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "router_threshold_scaling",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "block",
                        name: "schedule_scaling",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 2,
                        minItems: 1,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the AutoScale",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "trigger_type",
                        description: "This must be one of [`cpu`/`router`/`schedule`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "zones",
                        description: "List of zone names where monitored resources are located",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "cpu_threshold_scaling",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "number",
                                name: "down",
                                description: "Threshold for average CPU utilization to scale down/in",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "server_prefix",
                                description: "Server name prefix to be monitored",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "up",
                                description: "Threshold for average CPU utilization to scale up/out",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "router_threshold_scaling",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "direction",
                                description: "This must be one of [`in`/`out`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "mbps",
                                description: "Mbps",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "router_prefix",
                                description: "Router name prefix to be monitored",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "schedule_scaling",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 2,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "action",
                                description: "This must be one of [`up`/`down`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "days_of_week",
                                description: "A list of weekdays to backed up. The values in the list must be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 7,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "hour",
                                description: "Hour to be triggered",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "minute",
                                description: "Minute to be triggered. This must be one of [`0`/`15`/`30`/`45`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_bridge",
			type: "resource",
            displayName: "ブリッジ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Bridge. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Bridge. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Bridge will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_cdrom",
			type: "resource",
            displayName: "ISOイメージ",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "content",
                        description: "The content to upload to as the CD-ROM. This conflicts with [`iso_image_file`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "content_file_name",
                        description: "The name of content file to upload to as the CD-ROM. This is only used when `content` is specified. This conflicts with [`iso_image_file`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "config",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the CD-ROM. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "hash",
                        description: "The md5 checksum calculated from the base64 encoded file body",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the CD-ROM",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "iso_image_file",
                        description: "The file path to upload to as the CD-ROM. This conflicts with [`content`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the CD-ROM. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of CD-ROM in GiB. This must be one of [`5`/`10`/`20`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "5",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the CD-ROM",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the CD-ROM will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_certificate_authority",
			type: "resource",
            displayName: "マネージドPKI",
            category: "lab",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "client",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Certificate Authority. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Certificate Authority",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Certificate Authority. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "subject",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Certificate Authority",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "validity_period_hours",
                        description: "The number of hours after initial issuing that the certificate will become invalid",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "certificate",
                        description: "The body of the CA's certificate in PEM format",
                    },
                    {
                        type: "string",
                        name: "crl_url",
                        description: "The URL of the CRL",
                    },
                    {
                        type: "string",
                        name: "not_after",
                        description: "The date on which the certificate validity period ends, in RFC3339 format",
                    },
                    {
                        type: "string",
                        name: "not_before",
                        description: "The date on which the certificate validity period begins, in RFC3339 format",
                    },
                    {
                        type: "string",
                        name: "serial_number",
                        description: "The body of the CA's certificate in PEM format",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "client",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "csr",
                                description: "Input for issuing a certificate",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "email",
                                description: "Input for issuing a certificate",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "hold",
                                description: "Flag to suspend/hold the certificate",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "public_key",
                                description: "Input for issuing a certificate",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "subject",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 1,
                                minItems: 1,
                            },
                            {
                                type: "number",
                                name: "validity_period_hours",
                                description: "The number of hours after initial issuing that the certificate will become invalid",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "csr",
                                description: "Input for issuing a certificate",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "hold",
                                description: "Flag to suspend/hold the certificate",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "public_key",
                                description: "Input for issuing a certificate",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "subject",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 1,
                                minItems: 1,
                            },
                            {
                                type: "stringList",
                                name: "subject_alternative_names",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "validity_period_hours",
                                description: "The number of hours after initial issuing that the certificate will become invalid",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "subject",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: true,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "common_name",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "country",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "organization",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "organization_units",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "client",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "certificate",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The id of the certificate",
                            },
                            {
                                type: "string",
                                name: "issue_state",
                                description: "Current state of the certificate",
                            },
                            {
                                type: "string",
                                name: "not_after",
                                description: "The date on which the certificate validity period ends, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "not_before",
                                description: "The date on which the certificate validity period begins, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "serial_number",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "string",
                                name: "url",
                                description: "The URL for issuing the certificate",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "certificate",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The id of the certificate",
                            },
                            {
                                type: "string",
                                name: "issue_state",
                                description: "Current state of the certificate",
                            },
                            {
                                type: "string",
                                name: "not_after",
                                description: "The date on which the certificate validity period ends, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "not_before",
                                description: "The date on which the certificate validity period begins, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "serial_number",
                                description: "The body of the CA's certificate in PEM format",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_container_registry",
			type: "resource",
            displayName: "コンテナレジストリ",
            category: "lab",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "access_level",
                        description: "The level of access that allow to users. This must be one of [`readwrite`/`readonly`/`none`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Container Registry. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Container Registry",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Container Registry. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "subdomain_label",
                        description: "The label at the lowest of the FQDN used when be accessed from users. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Container Registry",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "user",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "virtual_domain",
                        description: "The alias for accessing the container registry",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "fqdn",
                        description: "The FQDN for accessing the Container Registry. FQDN is built from `subdomain_label` + `.sakuracr.jp`",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "user",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The user name used to authenticate remote access",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The password used to authenticate remote access",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "permission",
                                description: "The level of access that allow to the user. This must be one of [`all`/`readwrite`/`readonly`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_database",
			type: "resource",
            displayName: "データベース",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "backup",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "database_type",
                        description: "The type of the database. This must be one of [`mariadb`/`postgres`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "postgres",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "database_version",
                        description: "The version of the database",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Database. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Database",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Database. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "map",
                        name: "parameters",
                        description: "The map for setting RDBMS-specific parameters. Valid keys can be found with the `usacloud database list-parameters` command",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "password",
                        description: "The password of default user on the database",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the Database. This must be one of [`10g`/`30g`/`90g`/`240g`/`500g`/`1t`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "10g",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "replica_password",
                        description: "The password of user that processing a replication",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "replica_user",
                        description: "The name of user that processing a replication",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "replica",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Database",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "username",
                        description: "The name of default user on the database. The length of this value must be in the range [`3`-`20`]",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Database will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "backup",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "time",
                                description: "The time to take backup. This must be formatted with `HH:mm`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "weekdays",
                                description: "A list of weekdays to backed up. The values in the list must be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by Database",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address to assign to the Database",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet to assign to the Database. This must be in the range [`8`-`29`]",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The number of the listening port. This must be in the range [`1024`-`65535`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "5432",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "source_ranges",
                                description: "The range of source IP addresses that allow to access to the Database via network",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch to which the Database connects",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_database_read_replica",
			type: "resource",
            displayName: "データベース(リードレプリカ)",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the read-replica database. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the read-replica database",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "master_id",
                        description: "The id of the replication master database",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the read-replica database. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the read-replica database",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the read-replica database will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by read-replica database. If `gateway` isn't specified, it will be set to the same value of the master database",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address to assign to the read-replica database",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet to assign to the read-replica database. This must be in the range [`8`-`29`]. If `netmask` isn't specified, it will be set to the same value of the master database",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "source_ranges",
                                description: "The range of source IP addresses that allow to access to the read-replica database via network",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch to which the read-replica database connects. If `switch_id` isn't specified, it will be set to the same value of the master database",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_disk",
			type: "resource",
            displayName: "ディスク",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "connector",
                        description: "The name of the disk connector. This must be one of [`virtio`/`ide`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "virtio",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the disk. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "distant_from",
                        description: "A list of disk id. The disk will be located to different storage from these disks",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "encryption_algorithm",
                        description: "The disk encryption algorithm. This must be one of [`none`/`aes256_xts`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "none",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the disk",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the disk. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the disk. This must be one of [`ssd`/`hdd`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "ssd",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of disk in GiB",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "20",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source_archive_id",
                        description: "The id of the source archive. This conflicts with [`source_disk_id`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source_disk_id",
                        description: "The id of the source disk. This conflicts with [`source_archive_id`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the disk",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the disk will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "server_id",
                        description: "The id of the Server connected to the disk",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_dns",
			type: "resource",
            displayName: "DNSゾーン",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the DNS. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the DNS",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "record",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 2000,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the DNS",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The target zone. (e.g. `example.com`)",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "dns_servers",
                        description: "A list of IP address of DNS server that manage this zone",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "record",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 2000,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the DNS Record. The length of this value must be in the range [`1`-`64`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The number of port. This must be in the range [`1`-`65535`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "priority",
                                description: "The priority of target DNS Record. This must be in the range [`0`-`65535`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "ttl",
                                description: "The number of the TTL",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "3600",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "type",
                                description: "The type of DNS Record. This must be one of [`A`/`AAAA`/`ALIAS`/`CNAME`/`NS`/`MX`/`TXT`/`SRV`/`CAA`/`HTTPS`/`SVCB`/`PTR`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "The value of the DNS Record",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "weight",
                                description: "The weight of target DNS Record. This must be in the range [`0`-`65535`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_dns_record",
			type: "resource",
            displayName: "DNSレコード",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "dns_id",
                        description: "The id of the DNS resource",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the DNS Record resource",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "port",
                        description: "The number of port. This must be in the range [`1`-`65535`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "priority",
                        description: "The priority of target DNS Record. This must be in the range [`0`-`65535`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "ttl",
                        description: "The number of the TTL",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "3600",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "type",
                        description: "The type of DNS Record. This must be one of [`A`/`AAAA`/`ALIAS`/`CNAME`/`NS`/`MX`/`TXT`/`SRV`/`CAA`/`HTTPS`/`SVCB`/`PTR`]",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "value",
                        description: "The value of the DNS Record",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "weight",
                        description: "The weight of target DNS Record. This must be in the range [`0`-`65535`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_enhanced_db",
			type: "resource",
            displayName: "エンハンスドデータベース(TiDB)",
            category: "lab",
            schema: {
                arguments: [
                    {
                        type: "stringList",
                        name: "allowed_networks",
                        description: "A list of CIDR blocks allowed to connect",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "database_name",
                        description: "The name of database",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "database_type",
                        description: "The type of database. This must be one of [`tidb`/`mariadb`]",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Enhanced Database. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Enhanced Database",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Enhanced Database. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "password",
                        description: "The password of database",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "region",
                        description: "The name of region that the database is in. This must be one of [`is1`/`tk1`]",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Enhanced Database",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "hostname",
                        description: "The name of database host. This will be built from `database_name` + `tidb-is1.db.sakurausercontent.com`",
                    },
                    {
                        type: "number",
                        name: "max_connections",
                        description: "The value of max connections setting",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_esme",
			type: "resource",
            displayName: "2要素認証SMS",
            category: "sms",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the ESME. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the ESME",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the ESME. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the ESME",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "send_message_with_generated_otp_api_url",
                        description: "The API URL for send SMS with generated OTP",
                    },
                    {
                        type: "string",
                        name: "send_message_with_inputted_otp_api_url",
                        description: "The API URL for send SMS with inputted OTP",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_gslb",
			type: "resource",
            displayName: "GSLB",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the GSLB. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the GSLB",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the GSLB. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 12,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "sorry_server",
                        description: "The IP address of the SorryServer. This will be used when all servers are down",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the GSLB",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "weighted",
                        description: "The flag to enable weighted load-balancing",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "fqdn",
                        description: "The FQDN for accessing to the GSLB. This is typically used as value of CNAME record",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "number",
                                name: "delay_loop",
                                description: "The interval in seconds between checks. This must be in the range [`10`-`60`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "10",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "host_header",
                                description: "The value of host header send when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number used when checking by TCP/HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This must be one of [`http`/`https`/`tcp`/`ping`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "status",
                                description: "The response-code to expect when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 12,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable as destination of load balancing",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "true",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the server",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "weight",
                                description: "The weight used when weighted load balancing is enabled. This must be in the range [`1`-`10000`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "1",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_icon",
			type: "resource",
            displayName: "アイコン",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "base64content",
                        description: "The base64 encoded content to upload to as the Icon. This conflicts with [`source`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Icon. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "source",
                        description: "The file path to upload to as the Icon. This conflicts with [`base64content`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Icon",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "url",
                        description: "The URL for getting the icon's raw data",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_internet",
			type: "resource",
            displayName: "スイッチ+ルータ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "number",
                        name: "band_width",
                        description: "The bandwidth of the network connected to the Internet in Mbps. `100`/`250`/`500`/`1000`/`1500`/`2000`/`2500`/`3000`/`3500`/`4000`/`4500`/`5000`/`5500`/`6000`/`6500`/`7000`/`7500`/`8000`/`8500`/`9000`/`9500`/`10000`",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "100",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Switch+Router. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "enable_ipv6",
                        description: "The flag to enable IPv6",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Switch+Router",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Switch+Router. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "netmask",
                        description: "The bit length of the subnet assigned to the Switch+Router. `26`/`27`/`28`",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "28",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Switch+Router",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Switch+Router will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "gateway",
                        description: "The IP address of the gateway used by the Switch+Router",
                    },
                    {
                        type: "stringList",
                        name: "ip_addresses",
                        description: "A list of assigned global address to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "ipv6_network_address",
                        description: "The IPv6 network address assigned to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "ipv6_prefix",
                        description: "The network prefix of assigned IPv6 addresses to the Switch+Router",
                    },
                    {
                        type: "number",
                        name: "ipv6_prefix_len",
                        description: "The bit length of IPv6 network prefix",
                    },
                    {
                        type: "string",
                        name: "max_ip_address",
                        description: "Maximum IP address in assigned global addresses to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "min_ip_address",
                        description: "Minimum IP address in assigned global addresses to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "network_address",
                        description: "The IPv4 network address assigned to the Switch+Router",
                    },
                    {
                        type: "stringList",
                        name: "server_ids",
                        description: "A list of the ID of Servers connected to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "switch_id",
                        description: "The id of the switch",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_ipv4_ptr",
			type: "resource",
            displayName: "IPv4逆引きレコード",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "hostname",
                        description: "The value of the PTR record. This must be FQDN",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "ip_address",
                        description: "The IP address to which the PTR record is set",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "retry_interval",
                        description: "The wait interval(in seconds) for retrying API call used when SakuraCloud API returns any errors",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "10",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "retry_max",
                        description: "The maximum number of API call retries used when SakuraCloud API returns any errors",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "30",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the IPv4 PTR will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_load_balancer",
			type: "resource",
            displayName: "ロードバランサ",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the LoadBalancer. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the LoadBalancer",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the LoadBalancer. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the LoadBalancer. This must be one of [`standard`/`highspec`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "standard",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the LoadBalancer",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "vip",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 20,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the LoadBalancer will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by LoadBalancer",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "A list of IP address to assign to the LoadBalancer. ",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 2,
                                minItems: 1,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the LoadBalancer. This must be in the range [`8`-`29`]",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch to which the LoadBalancer connects",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "vrid",
                                description: "The Virtual Router Identifier",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "vip",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 20,
                        minItems: 0,
                        arguments: [
                            {
                                type: "number",
                                name: "delay_loop",
                                description: "The interval in seconds between checks. This must be in the range [`10`-`2147483647`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "10",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the VIP. The length of this value must be in the range [`1`-`512`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The target port number for load-balancing. This must be in the range [`1`-`65535`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "server",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 40,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "sorry_server",
                                description: "The IP address of the SorryServer. This will be used when all servers under this VIP are down",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 40,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable as destination of load balancing",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "true",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the destination server",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This must be one of [`http`/`https`/`tcp`/`ping`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "status",
                                description: "The response code to expect when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_local_router",
			type: "resource",
            displayName: "ローカルルータ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the LocalRouter. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the LocalRouter",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the LocalRouter. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "block",
                        name: "peer",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "switch",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the LocalRouter",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "secret_keys",
                        description: "A list of secret key used for peering from other LocalRouters",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "A list of IP address to assign to the LocalRouter. ",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 2,
                                minItems: 2,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the LocalRouter. This must be in the range [`8`-`29`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "vrid",
                                description: "The Virtual Router Identifier",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "peer",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the LocalRouter. The length of this value must be in the range [`1`-`512`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable the LocalRouter",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "true",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "peer_id",
                                description: "The ID of the peer LocalRouter",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "secret_key",
                                description: "The secret key of the peer LocalRouter",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "next_hop",
                                description: "The IP address of the next hop",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "prefix",
                                description: "The CIDR block of destination",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "switch",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "category",
                                description: "The category name of connected services (e.g. `cloud`, `vps`)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "cloud",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "code",
                                description: "The resource ID of the Switch",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "zone_id",
                                description: "The id of the Zone",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_mobile_gateway",
			type: "resource",
            displayName: "モバイルゲートウェイ ",
            category: "securemobile",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the MobileGateway. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "dns_servers",
                        description: "A list of IP address used by each connected devices",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 2,
                        minItems: 2,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the MobileGateway",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "inter_device_communication",
                        description: "The flag to allow communication between each connected devices",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "false",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "internet_connection",
                        description: "The flag to enable connect to the Internet",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "false",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the MobileGateway. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "private_network_interface",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "sim",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "sim_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the MobileGateway",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "traffic_control",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the MobileGateway will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "public_ip",
                        description: "The public IP address assigned to the MobileGateway",
                    },
                    {
                        type: "number",
                        name: "public_netmask",
                        description: "The bit length of the subnet assigned to the MobileGateway",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "private_network_interface",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address to assign to the MobileGateway",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet to assign to the MobileGateway. This must be in the range [`8`-`29`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch to which the MobileGateway connects",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "sim",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address to assign to the SIM",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "sim_id",
                                description: "The id of the Switch connected to the MobileGateway",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "sim_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "prefix",
                                description: "The destination network prefix used by the sim routing. This must be specified by CIDR block formatted string",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "sim_id",
                                description: "The id of the routing destination SIM",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "next_hop",
                                description: "The IP address of next hop",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "prefix",
                                description: "The destination network prefix used by static routing. This must be specified by CIDR block formatted string",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "traffic_control",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "auto_traffic_shaping",
                                description: "The flag to enable the traffic shaping",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "band_width_limit",
                                description: "The bandwidth allowed when the traffic shaping is enabled",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "enable_email",
                                description: "The flag to enable email notification when the traffic shaping is enabled",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "enable_slack",
                                description: "The flag to enable slack notification when the traffic shaping is enabled",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "quota",
                                description: "The threshold of monthly traffic usage to enable to the traffic shaping",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "slack_webhook",
                                description: "The webhook URL used when sends notification. It will only used when `enable_slack` is set `true`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_nfs",
			type: "resource",
            displayName: "NFS",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the NFS. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the NFS",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the NFS. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the NFS. This must be one of [`hdd`/`ssd`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "hdd",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of NFS in GiB",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "100",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the NFS",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the NFS will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by NFS",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address to assign to the NFS",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet to assign to the NFS. This must be in the range [`8`-`29`]",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch to which the NFS connects",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_note",
			type: "resource",
            displayName: "スタートアップスクリプト",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "class",
                        description: "The class of the Note. This must be one of `shell`/`yaml_cloud_config`",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "shell",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "content",
                        description: "The content of the Note. This must be specified as a shell script or as a cloud-config",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Note",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Note. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Note",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Note. This will be computed from special tags within body of `content`",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_packet_filter",
			type: "resource",
            displayName: "パケットフィルタ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the packetFilter. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 30,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the packetFilter. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the packetFilter will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 30,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "allow",
                                description: "The flag to allow the packet through the filter",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "true",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the expression",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "destination_port",
                                description: "A destination port number or port range used for filtering (e.g. `1024`, `1024-2048`)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for filtering. This must be one of [`http`/`https`/`tcp`/`udp`/`icmp`/`fragment`/`ip`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_network",
                                description: "A source IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_port",
                                description: "A source port number or port range used for filtering (e.g. `1024`, `1024-2048`)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_packet_filter_rules",
			type: "resource",
            displayName: "パケットフィルタ ルール",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 30,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "packet_filter_id",
                        description: "The id of the packet filter that set expressions to",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the PacketFilter Rule will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: true,
                        maxItems: 30,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "allow",
                                description: "The flag to allow the packet through the filter",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "true",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the expression",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "destination_port",
                                description: "A destination port number or port range used for filtering (e.g. `1024`, `1024-2048`)",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for filtering. This must be one of [`http`/`https`/`tcp`/`udp`/`icmp`/`fragment`/`ip`]",
                                required: true,
                                optional: false,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_network",
                                description: "A source IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_port",
                                description: "A source port number or port range used for filtering (e.g. `1024`, `1024-2048`)",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_private_host",
			type: "resource",
            displayName: "専有ホスト",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "class",
                        description: "The class of the PrivateHost. This will be one of [`dynamic`/`ms_windows`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "dynamic",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the PrivateHost. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the PrivateHost",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the PrivateHost. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the PrivateHost",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the PrivateHost will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "number",
                        name: "assigned_core",
                        description: "The total number of CPUs assigned to servers on the private host",
                    },
                    {
                        type: "number",
                        name: "assigned_memory",
                        description: "The total size of memory assigned to servers on the private host",
                    },
                    {
                        type: "string",
                        name: "hostname",
                        description: "The hostname of the private host",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_proxylb",
			type: "resource",
            displayName: "エンハンスドLB",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "backend_http_keep_alive",
                        description: "Mode of http keep-alive with backend. This must be one of [`safe`/`aggressive`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "safe",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "bind_port",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 2,
                        minItems: 1,
                    },
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the ProxyLB. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "gzip",
                        description: "The flag to enable gzip compression",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the ProxyLB",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the ProxyLB. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "plan",
                        description: "The plan name of the ProxyLB. This must be one of [`100`/`500`/`1000`/`5000`/`10000`/`50000`/`100000`/`400000`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "100",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "proxy_protocol",
                        description: "The flag to enable proxy protocol v2",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "region",
                        description: "The name of region that the proxy LB is in. This must be one of [`tk1`/`is1`/`anycast`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "is1",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "rule",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 40,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "sorry_server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "sticky_session",
                        description: "The flag to enable sticky session",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "syslog",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the ProxyLB",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "timeout",
                        description: "The timeout duration in seconds",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "10",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "vip_failover",
                        description: "The flag to enable VIP fail-over",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "fqdn",
                        description: "The FQDN for accessing to the ProxyLB. This is typically used as value of CNAME record",
                    },
                    {
                        type: "block",
                        name: "letsencrypt",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "proxy_networks",
                        description: "A list of CIDR block used by the ProxyLB to access the server",
                    },
                    {
                        type: "string",
                        name: "vip",
                        description: "The virtual IP address assigned to the ProxyLB",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "bind_port",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 2,
                        minItems: 1,
                        arguments: [
                            {
                                type: "number",
                                name: "port",
                                description: "The number of listening port",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "proxy_mode",
                                description: "The proxy mode. This must be one of [`http`/`https`/`tcp`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "redirect_to_https",
                                description: "The flag to enable redirection from http to https. This flag is used only when `proxy_mode` is `http`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "response_header",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 10,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ssl_policy",
                                description: "The ssl policy. This must be one of [`TLS-1-2-2019-04`/`TLS-1-2-2021-06`/`TLS-1-3-2021-06`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "support_http2",
                                description: "The flag to enable HTTP/2. This flag is used only when `proxy_mode` is `https`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "response_header",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 10,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "header",
                                description: "The field name of HTTP header added to response by the ProxyLB",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "The field value of HTTP header added to response by the ProxyLB",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "additional_certificate",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 19,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "intermediate_cert",
                                description: "The intermediate certificate for a server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "private_key",
                                description: "The private key for a server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "server_cert",
                                description: "The certificate for a server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "additional_certificate",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 19,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "intermediate_cert",
                                description: "The intermediate certificate for a server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "private_key",
                                description: "The private key for a server",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "server_cert",
                                description: "The certificate for a server",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "number",
                                name: "delay_loop",
                                description: "The interval in seconds between checks. This must be in the range [`10`-`60`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "10",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "host_header",
                                description: "The value of host header send when checking by HTTP",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number used when checking by TCP",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This must be one of [`http`/`tcp`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "rule",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "action",
                                description: "The type of action to be performed when requests matches the rule. This must be one of [`forward`/`redirect`/`fixed`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "forward",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "fixed_content_type",
                                description: "Content-Type header value for fixed response sent when requests matches the rule. This must be one of [`text/plain`/`text/html`/`application/javascript`/`application/json`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "fixed_message_body",
                                description: "Content body for fixed response sent when requests matches the rule",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "fixed_status_code",
                                description: "HTTP status code for fixed response sent when requests matches the rule. This must be one of [`200`/`403`/`503`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "group",
                                description: "The name of load balancing group. When proxyLB received request which matched to `host` and `path`, proxyLB forwards the request to servers that having same group name. The length of this value must be in the range [`1`-`10`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "host",
                                description: "The value of HTTP host header that is used as condition of rule-based balancing",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The request path that is used as condition of rule-based balancing",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "redirect_location",
                                description: "The URL to redirect to when the request matches the rule. see https://manual.sakura.ad.jp/cloud/appliance/enhanced-lb/#enhanced-lb-rule for details",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "redirect_status_code",
                                description: "HTTP status code for redirects sent when requests matches the rule. This must be one of [`301`/`302`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "request_header_name",
                                description: "The header name that the client will send when making a request",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "request_header_value",
                                description: "The condition for the value of the request header specified by the request header name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "request_header_value_ignore_case",
                                description: "Boolean value representing whether the request header value ignores case",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "request_header_value_not_match",
                                description: "Boolean value representing whether to apply the rules when the request header value conditions are met or when the conditions do not match",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_ips",
                                description: "IP address or CIDR block to which the rule will be applied. Multiple values can be specified by separating them with a space or comma",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 40,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable as destination of load balancing",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "true",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "group",
                                description: "The name of load balancing group. This is used when using rule-based load balancing. The length of this value must be in the range [`1`-`10`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the destination server",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number of the destination server. This must be in the range [`1`-`65535`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "sorry_server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the SorryServer. This will be used when all servers are down",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number of the SorryServer. This will be used when all servers are down",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "syslog",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "number",
                                name: "port",
                                description: "The number of syslog port",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "server",
                                description: "The address of syslog server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "common_name",
                                description: "The common name of the certificate",
                            },
                            {
                                type: "string",
                                name: "subject_alt_names",
                                description: "The subject alternative names of the certificate",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "letsencrypt",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "common_name",
                                description: "The common name of the certificate",
                            },
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to accept the current Let's Encrypt terms of service(see: https://letsencrypt.org/repository/). This must be set `true` explicitly",
                            },
                            {
                                type: "stringList",
                                name: "subject_alt_names",
                                description: "The subject alternative names of the certificate",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_proxylb_acme",
			type: "resource",
            displayName: "エンハンスドLB ACME",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "boolean",
                        name: "accept_tos",
                        description: "The flag to accept the current Let's Encrypt terms of service(see: https://letsencrypt.org/repository/). This must be set `true` explicitly",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "common_name",
                        description: "The FQDN used by ACME. This must set resolvable value",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "get_certificates_timeout_sec",
                        description: "The timeout in seconds for the certificate acquisition to complete",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "120",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "proxylb_id",
                        description: "The id of the ProxyLB that set ACME settings to",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "subject_alt_names",
                        description: "The Subject alternative names used by ACME",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "update_delay_sec",
                        description: "The wait time in seconds. This typically used for waiting for a DNS propagation",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                        attributes: [
                            {
                                type: "block",
                                name: "additional_certificate",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "common_name",
                                description: "The common name of the certificate",
                            },
                            {
                                type: "string",
                                name: "intermediate_cert",
                                description: "The intermediate certificate for a server",
                            },
                            {
                                type: "string",
                                name: "private_key",
                                description: "The private key for a server",
                            },
                            {
                                type: "string",
                                name: "server_cert",
                                description: "The certificate for a server",
                            },
                            {
                                type: "string",
                                name: "subject_alt_names",
                                description: "The subject alternative names of the certificate",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "additional_certificate",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "intermediate_cert",
                                description: "The intermediate certificate for a server",
                            },
                            {
                                type: "string",
                                name: "private_key",
                                description: "The private key for a server",
                            },
                            {
                                type: "string",
                                name: "server_cert",
                                description: "The certificate for a server",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_server",
			type: "resource",
            displayName: "サーバ",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "cdrom_id",
                        description: "The id of the CD-ROM to attach to the Server",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "commitment",
                        description: "The policy of how to allocate virtual CPUs to the server. This must be one of [`standard`/`dedicatedcpu`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "standard",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "core",
                        description: "The number of virtual CPUs",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "1",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "cpu_model",
                        description: "The model of cpu",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Server. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "disk_edit_parameter",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "stringList",
                        name: "disks",
                        description: "A list of disk id connected to the server",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "force_shutdown",
                        description: "The flag to use force shutdown when need to reboot/shutdown while applying",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "gpu",
                        description: "The number of GPUs",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Server",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "interface_driver",
                        description: "The driver name of network interface. This must be one of [`virtio`/`e1000`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "virtio",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "memory",
                        description: "The size of memory in GiB",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "1",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Server. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 10,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "private_host_id",
                        description: "The id of the PrivateHost which the Server is assigned",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Server",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "user_data",
                        description: "A string representing the user data used by cloud-init. This conflicts with [`disk_edit_parameter`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Server will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "dns_servers",
                        description: "A list of IP address of DNS server in the zone",
                    },
                    {
                        type: "string",
                        name: "gateway",
                        description: "The IP address of the gateway used by Server",
                    },
                    {
                        type: "string",
                        name: "hostname",
                        description: "The hostname of the Server",
                    },
                    {
                        type: "string",
                        name: "ip_address",
                        description: "The IP address assigned to the Server",
                    },
                    {
                        type: "number",
                        name: "netmask",
                        description: "The bit length of the subnet assigned to the Server",
                    },
                    {
                        type: "string",
                        name: "network_address",
                        description: "The network address which the `ip_address` belongs",
                    },
                    {
                        type: "string",
                        name: "private_host_name",
                        description: "The id of the PrivateHost which the Server is assigned",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "disk_edit_parameter",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "boolean",
                                name: "change_partition_uuid",
                                description: "The flag to change partition uuid",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "disable_pw_auth",
                                description: "The flag to disable password authentication",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "enable_dhcp",
                                description: "The flag to enable DHCP client",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "gateway",
                                description: "The gateway address used by the Server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "hostname",
                                description: "The hostname of the Server. The length of this value must be in the range [`1`-`64`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address to assign to the Server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet to assign to the Server",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "note",
                                description: "A list of the Note/StartupScript",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "note_ids",
                                description: "A list of the Note id",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The password of default user. The length of this value must be in the range [`8`-`64`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "ssh_key_ids",
                                description: "A list of the SSHKey id",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "ssh_keys",
                                description: "A list of the SSHKey text",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "note",
                        description: "A list of the Note/StartupScript",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "api_key_id",
                                description: "The id of the API key to be injected into note when editing the disk",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The id of the note",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "map",
                                name: "variables",
                                description: "The value of the variable that be injected into note when editing the disk",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 10,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "packet_filter_id",
                                description: "The id of the packet filter to attach to the network interface",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "upstream",
                                description: "The upstream type or upstream switch id. This must be one of [`shared`/`disconnect`/`<switch id>`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "user_ip_address",
                                description: "The IP address for only display. This value doesn't affect actual NIC settings",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "mac_address",
                                description: "The MAC address",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_sim",
			type: "resource",
            displayName: "SIM",
            category: "securemobile",
            schema: {
                arguments: [
                    {
                        type: "stringList",
                        name: "carrier",
                        description: "A list of a communication company. Each element must be one of `kddi`/`docomo`/`softbank`",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 3,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the SIM. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "enabled",
                        description: "The flag to enable the SIM",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "true",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "iccid",
                        description: "ICCID(Integrated Circuit Card ID) assigned to the SIM",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the SIM",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "imei",
                        description: "The id of the device to restrict devices that can use the SIM",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the SIM. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "passcode",
                        description: "The passcord to authenticate the SIM",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the SIM",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "ip_address",
                        description: "The IP address assigned to the SIM",
                    },
                    {
                        type: "string",
                        name: "mobile_gateway_id",
                        description: "The id of the MobileGateway which the SIM is assigned",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_simple_monitor",
			type: "resource",
            displayName: "シンプル監視",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "number",
                        name: "delay_loop",
                        description: "The interval in seconds between checks. This must be in the range [`60`-`3600`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "60",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the SimpleMonitor. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "enabled",
                        description: "The flag to enable monitoring by the simple monitor",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "true",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the SimpleMonitor",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "max_check_attempts",
                        description: "The number of retry. This must be in the range [`1`-`10`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "notify_email_enabled",
                        description: "The flag to enable notification by email",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "true",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "notify_email_html",
                        description: "The flag to enable HTML format instead of text format",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "false",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "notify_interval",
                        description: "The interval in hours between notification. This must be in the range [`1`-`72`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "2",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "notify_slack_enabled",
                        description: "The flag to enable notification by slack/discord",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "false",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "notify_slack_webhook",
                        description: "The webhook URL for sending notification by slack/discord",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "retry_interval",
                        description: "The interval in seconds between retries. This must be in the range [`10`-`3600`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the SimpleMonitor",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "target",
                        description: "The monitoring target of the simple monitor. This must be IP address or FQDN",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "timeout",
                        description: "The timeout in seconds for monitoring. This must be in the range [`10`-`30`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "string",
                                name: "community",
                                description: "The SNMP community string used when checking by SNMP",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "contains_string",
                                description: "The string that should be included in the response body when checking for HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "excepcted_data",
                                description: "The expected value used when checking by DNS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "ftps",
                                description: "The methods of invoking security for monitoring with FTPS. This must be one of [``/`implicit`/`explicit`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "host_header",
                                description: "The value of host header send when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "http2",
                                description: "The flag to enable HTTP/2 when checking by HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "oid",
                                description: "The SNMP OID used when checking by SNMP",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The password for basic auth used when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The target port number",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This must be one of [`http`/`https`/`ping`/`tcp`/`dns`/`ssh`/`smtp`/`pop3`/`snmp`/`sslcertificate`/`ftp`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "qname",
                                description: "The FQDN used when checking by DNS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "remaining_days",
                                description: "The number of remaining days until certificate expiration used when checking SSL certificates. This must be in the range [`1`-`9999`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "30",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "sni",
                                description: "The flag to enable SNI when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "snmp_version",
                                description: "The SNMP version used when checking by SNMP. This must be one of `1`/`2c`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "status",
                                description: "The response-code to expect when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "username",
                                description: "The user name for basic auth used when checking by HTTP/HTTPS",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "verify_sni",
                                description: "The flag to enable hostname verification for SNI",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_ssh_key",
			type: "resource",
            displayName: "SSH公開鍵",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the SSHKey. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the SSHKey. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "public_key",
                        description: "The body of the public key",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "fingerprint",
                        description: "The fingerprint of the public key",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_ssh_key_gen",
			type: "resource",
            displayName: "SSH公開鍵(生成)",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the SSHKey. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the SSHKey. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "pass_phrase",
                        description: "The pass phrase of the private key. The length of this value must be in the range [`8`-`64`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "fingerprint",
                        description: "The fingerprint of the public key",
                    },
                    {
                        type: "string",
                        name: "private_key",
                        description: "The body of the private key",
                    },
                    {
                        type: "string",
                        name: "public_key",
                        description: "The body of the public key",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_subnet",
			type: "resource",
            displayName: "サブネット",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "internet_id",
                        description: "The id of the switch+router resource that the subnet belongs",
                        required: true,
                        optional: false,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "netmask",
                        description: "The bit length of the subnet to assign to the Subnet. This must be in the range [`26`-`28`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "28",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "next_hop",
                        description: "The ip address of the next-hop at the subnet",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Subnet will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "ip_addresses",
                        description: "A list of assigned global address to the subnet",
                    },
                    {
                        type: "string",
                        name: "max_ip_address",
                        description: "Maximum IP address in assigned global addresses to the subnet",
                    },
                    {
                        type: "string",
                        name: "min_ip_address",
                        description: "Minimum IP address in assigned global addresses to the subnet",
                    },
                    {
                        type: "string",
                        name: "network_address",
                        description: "The IPv4 network address assigned to the Subnet",
                    },
                    {
                        type: "string",
                        name: "switch_id",
                        description: "The id of the switch connected from the Subnet",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_switch",
			type: "resource",
            displayName: "スイッチ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "bridge_id",
                        description: "The bridge id attached to the Switch",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Switch. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the Switch",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Switch. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the Switch",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Switch will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "server_ids",
                        description: "A list of server id connected to the switch",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_vpc_router",
			type: "resource",
            displayName: "VPCルータ",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the VPCRouter. The length of this value must be in the range [`1`-`512`]",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "dhcp_server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "dhcp_static_mapping",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "dns_forwarding",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "firewall",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id to attach to the VPCRouter",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "boolean",
                        name: "internet_connection",
                        description: "The flag to enable connecting to the Internet from the VPC Router",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "true",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "l2tp",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the VPCRouter. The length of this value must be in the range [`1`-`64`]",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the VPCRouter. This must be one of [`standard`/`premium`/`highspec`/`highspec4000`]",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "standard",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "port_forwarding",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "pptp",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "private_network_interface",
                        description: "A list of additional network interface setting. This doesn't include primary network interface setting",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 7,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "public_network_interface",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "block",
                        name: "scheduled_maintenance",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn_parameter",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "static_nat",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "syslog_host",
                        description: "The ip address of the syslog host to which the VPC Router sends logs",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags to assign to the VPCRouter",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "user",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 100,
                        minItems: 0,
                    },
                    {
                        type: "number",
                        name: "version",
                        description: "The version of the VPC Router",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "2",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "block",
                        name: "wire_guard",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the VPCRouter will be created (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "public_ip",
                        description: "The public ip address of the VPC Router",
                    },
                    {
                        type: "number",
                        name: "public_netmask",
                        description: "The bit length of the subnet to assign to the public network interface",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "dhcp_server",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "stringList",
                                name: "dns_servers",
                                description: "A list of IP address of DNS server to assign to DHCP client",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "interface_index",
                                description: "The index of the network interface on which to enable the DHCP service. This must be in the range [`1`-`7`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "range_start",
                                description: "The start value of IP address range to assign to DHCP client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "range_stop",
                                description: "The end value of IP address range to assign to DHCP client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "dhcp_static_mapping",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The static IP address to assign to DHCP client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "mac_address",
                                description: "The source MAC address of static mapping",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "dns_forwarding",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "stringList",
                                name: "dns_servers",
                                description: "A list of IP address of DNS server to forward to",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "interface_index",
                                description: "The index of the network interface on which to enable the DNS forwarding service. This must be in the range [`1`-`7`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "firewall",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "direction",
                                description: "The direction to apply the firewall. This must be one of [`send`/`receive`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "expression",
                                description: "",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "interface_index",
                                description: "The index of the network interface on which to enable filtering. This must be in the range [`0`-`7`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "allow",
                                description: "The flag to allow the packet through the filter",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the expression. The length of this value must be in the range [`0`-`512`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "destination_network",
                                description: "A destination IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "destination_port",
                                description: "A destination port number or port range used for filtering (e.g. `1024`, `1024-2048`). This is only used when `protocol` is `tcp` or `udp`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "logging",
                                description: "The flag to enable packet logging when matching the expression",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for filtering. This must be one of [`tcp`/`udp`/`icmp`/`ip`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_network",
                                description: "A source IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "source_port",
                                description: "A source port number or port range used for filtering (e.g. `1024`, `1024-2048`). This is only used when `protocol` is `tcp` or `udp`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "l2tp",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "pre_shared_secret",
                                description: "The pre shared secret for L2TP/IPsec",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "range_start",
                                description: "The start value of IP address range to assign to L2TP/IPsec client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "range_stop",
                                description: "The end value of IP address range to assign to L2TP/IPsec client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "port_forwarding",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the port forwarding. The length of this value must be in the range [`0`-`512`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "private_ip",
                                description: "The destination ip address of the port forwarding",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "private_port",
                                description: "The destination port number of the port forwarding. This will be a port number on a private network",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for port forwarding. This must be one of [`tcp`/`udp`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "public_port",
                                description: "The source port number of the port forwarding. This must be a port number on a public network",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "pptp",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "range_start",
                                description: "The start value of IP address range to assign to PPTP client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "range_stop",
                                description: "The end value of IP address range to assign to PPTP client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "private_network_interface",
                        description: "A list of additional network interface setting. This doesn't include primary network interface setting",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 7,
                        minItems: 0,
                        arguments: [
                            {
                                type: "number",
                                name: "index",
                                description: "The index of the network interface. This must be in the range [`1`-`7`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "A list of ip address to assign to the network interface. This is required only one value when `plan` is `standard`, two values otherwise",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 2,
                                minItems: 1,
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet to assign to the network interface",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the connected switch",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address to assign to the network interface. This is only required when `plan` is not `standard`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "public_network_interface",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "stringList",
                                name: "aliases",
                                description: "A list of ip alias to assign to the VPC Router. This can only be specified if `plan` is not `standard`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 19,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "The list of the IP address to assign to the VPC Router. This is required only one value when `plan` is `standard`, two values otherwise",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 2,
                                minItems: 2,
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch to connect. This is only required when when `plan` is not `standard`",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address of the VPC Router. This is only required when `plan` is not `standard`",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "vrid",
                                description: "The Virtual Router Identifier. This is only required when `plan` is not `standard`",
                                required: false,
                                optional: true,
                                forceNew: true,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "scheduled_maintenance",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "day_of_week",
                                description: "The value must be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "mon",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "hour",
                                description: "The time to start maintenance",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "3",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "stringList",
                                name: "local_prefix",
                                description: "A list of CIDR block of the network under the VPC Router",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "peer",
                                description: "The IP address of the opposing appliance connected to the VPC Router",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "pre_shared_secret",
                                description: "The pre shared secret for the VPN. The length of this value must be in the range [`0`-`40`]",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "remote_id",
                                description: "The id of the opposing appliance connected to the VPC Router. This is typically set same as value of `peer`",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "routes",
                                description: "A list of CIDR block of VPN connected networks",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn_parameter",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "dh_group",
                                description: "This must be one of [`modp1024`/`modp2048`/`modp3072`/`modp4096`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "encryption_algo",
                                description: "This must be one of [`aes128`/`aes256`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "esp",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "hash_algo",
                                description: "This must be one of [`sha1`/`sha256`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "ike",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "esp",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "number",
                                name: "lifetime",
                                description: "Default: 1800",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "ike",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "dpd",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "lifetime",
                                description: "Lifetime of IKE SA. Default: 28800",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "dpd",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "number",
                                name: "interval",
                                description: "Default: 15",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "number",
                                name: "timeout",
                                description: "Default: 30",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_nat",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the static nat. The length of this value must be in the range [`0`-`512`]",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "private_ip",
                                description: "The private IP address used for the static NAT",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "public_ip",
                                description: "The public IP address used for the static NAT",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "next_hop",
                                description: "The IP address of the next hop",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "prefix",
                                description: "The CIDR block of destination",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "user",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 100,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The user name used to authenticate remote access",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The password used to authenticate remote access",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "wire_guard",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address for WireGuard server. This must be formatted with xxx.xxx.xxx.xxx/nn",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "peer",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "peer",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address for peer",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "name",
                                description: "the of the peer",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "public_key",
                                description: "the public key of the WireGuard client",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "wire_guard",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "public_key",
                                description: "the public key of the WireGuard server",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_webaccel_acl",
			type: "resource",
            displayName: "sakuracloud_webaccel_acl",
            category: "",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "acl",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "site_id",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_webaccel_certificate",
			type: "resource",
            displayName: "ウェブアクセラレータ証明書",
            category: "webaccel",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "certificate_chain",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "private_key",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "site_id",
                        description: "",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "dns_names",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "issuer_common_name",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "not_after",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "not_before",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "serial_number",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "sha256_fingerprint",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "subject_common_name",
                        description: "",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
    ],
    dataSources: [
        {
            name: "sakuracloud_apprun_application",
			type: "data",
            displayName: "AppRunアプリケーション",
            category: "apprun",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "name",
                        description: "The name of application",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "block",
                        name: "components",
                        description: "The application component information",
                    },
                    {
                        type: "number",
                        name: "max_scale",
                        description: "The maximum number of scales for the entire application",
                    },
                    {
                        type: "number",
                        name: "min_scale",
                        description: "The minimum number of scales for the entire application",
                    },
                    {
                        type: "number",
                        name: "port",
                        description: "The port number where the application listens for requests",
                    },
                    {
                        type: "string",
                        name: "public_url",
                        description: "The public URL",
                    },
                    {
                        type: "string",
                        name: "status",
                        description: "The application status",
                    },
                    {
                        type: "number",
                        name: "timeout_seconds",
                        description: "The time limit between accessing the application's public URL, starting the instance, and receiving a response",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "components",
                        description: "The application component information",
                        required: false,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "env",
                                description: "The environment variables passed to components",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "block",
                                name: "probe",
                                description: "The component probe settings",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "deploy_source",
                        description: "The sources that make up the component",
                        required: false,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "container_registry",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 1,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "container_registry",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "server",
                                description: "The container registry server name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "username",
                                description: "The container registry credentials",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "env",
                        description: "The environment variables passed to components",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "key",
                                description: "The environment variable name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "environment variable value",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "http_get",
                        description: "",
                        required: false,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "block",
                                name: "headers",
                                description: "",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "headers",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The header field name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "The header field value",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "components",
                        description: "The application component information",
                        attributes: [
                            {
                                type: "block",
                                name: "deploy_source",
                                description: "The sources that make up the component",
                            },
                            {
                                type: "string",
                                name: "max_cpu",
                                description: "The maximum number of CPUs for a component",
                            },
                            {
                                type: "string",
                                name: "max_memory",
                                description: "The maximum memory of component",
                            },
                            {
                                type: "string",
                                name: "name",
                                description: "The component name",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "container_registry",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "image",
                                description: "The container image name",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "probe",
                        description: "The component probe settings",
                        attributes: [
                            {
                                type: "block",
                                name: "http_get",
                                description: "",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "http_get",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "path",
                                description: "The path to access HTTP server to check probes",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number for accessing HTTP server and checking probes",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_archive",
			type: "data",
            displayName: "アーカイブ",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "os_type",
                        description: "The criteria used to filter SakuraCloud archives. This must be one of following:`centos`/`centos7`/`almalinux`/`almalinux9`/`almalinux8`/`rockylinux`/`rockylinux9`/`rockylinux8`/`miracle`/`miraclelinux`/`miracle8`/`miraclelinux8`/`miracle9`/`miraclelinux9`/`ubuntu`/`ubuntu2204`/`ubuntu2004`/`ubuntu1804`/`debian`/`debian10`/`debian11`/`kusanagi`",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Archive is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Archive",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Archive",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Archive",
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of Archive in GiB",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Archive",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_auto_scale",
			type: "data",
            displayName: "オートスケール",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "api_key_id",
                        description: "The id of the API key",
                    },
                    {
                        type: "string",
                        name: "config",
                        description: "The configuration file for sacloud/autoscaler",
                    },
                    {
                        type: "block",
                        name: "cpu_threshold_scaling",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the AutoScale",
                    },
                    {
                        type: "boolean",
                        name: "disabled",
                        description: "The flag to stop trigger",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the AutoScale",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the AutoScale",
                    },
                    {
                        type: "block",
                        name: "router_threshold_scaling",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "schedule_scaling",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the AutoScale",
                    },
                    {
                        type: "string",
                        name: "trigger_type",
                        description: "This must be one of [`cpu`/`router`/`scheudle`]",
                    },
                    {
                        type: "stringList",
                        name: "zones",
                        description: "List of zone names where monitored resources are located",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "cpu_threshold_scaling",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "down",
                                description: "Threshold for average CPU utilization to scale down/in",
                            },
                            {
                                type: "string",
                                name: "server_prefix",
                                description: "Server name prefix to be monitored",
                            },
                            {
                                type: "number",
                                name: "up",
                                description: "Threshold for average CPU utilization to scale up/out",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "router_threshold_scaling",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "direction",
                                description: "This must be one of [`in`/`out`]",
                            },
                            {
                                type: "number",
                                name: "mbps",
                                description: "Mbps",
                            },
                            {
                                type: "string",
                                name: "router_prefix",
                                description: "Router name prefix to be monitored",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "schedule_scaling",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "action",
                                description: "This must be one of [`up`/`down`]",
                            },
                            {
                                type: "stringList",
                                name: "days_of_week",
                                description: "A list of weekdays to backed up. The values in the list must be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                            },
                            {
                                type: "number",
                                name: "hour",
                                description: "Hour to be triggered",
                            },
                            {
                                type: "number",
                                name: "minute",
                                description: "Minute to be triggered. This must be one of [`0`/`15`/`30`/`45`]",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_bridge",
			type: "data",
            displayName: "ブリッジ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Bridge is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Bridge",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Bridge",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_cdrom",
			type: "data",
            displayName: "ISOイメージ",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the CD-ROM is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the CD-ROM",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the CD-ROM",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the CD-ROM",
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of CD-ROM in GiB",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the CD-ROM",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_certificate_authority",
			type: "data",
            displayName: "マネージドPKI",
            category: "lab",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "certificate",
                        description: "The body of the CA's certificate in PEM format",
                    },
                    {
                        type: "block",
                        name: "client",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "crl_url",
                        description: "The URL of the CRL",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the CertificateAuthority",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the CertificateAuthority",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the CertificateAuthority",
                    },
                    {
                        type: "string",
                        name: "not_after",
                        description: "The date on which the certificate validity period ends, in RFC3339 format",
                    },
                    {
                        type: "string",
                        name: "not_before",
                        description: "The date on which the certificate validity period begins, in RFC3339 format",
                    },
                    {
                        type: "string",
                        name: "serial_number",
                        description: "The body of the CA's certificate in PEM format",
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "subject_string",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the CertificateAuthority",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        required: false,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "boolean",
                                name: "hold",
                                description: "Flag to suspend/hold the certificate",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "client",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "certificate",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "boolean",
                                name: "hold",
                                description: "Flag to suspend/hold the certificate",
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The id of the certificate",
                            },
                            {
                                type: "string",
                                name: "issue_state",
                                description: "Current state of the certificate",
                            },
                            {
                                type: "string",
                                name: "not_after",
                                description: "The date on which the certificate validity period ends, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "not_before",
                                description: "The date on which the certificate validity period begins, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "serial_number",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "string",
                                name: "subject_string",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "url",
                                description: "The URL for issuing the certificate",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "certificate",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The id of the certificate",
                            },
                            {
                                type: "string",
                                name: "issue_state",
                                description: "Current state of the certificate",
                            },
                            {
                                type: "string",
                                name: "not_after",
                                description: "The date on which the certificate validity period ends, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "not_before",
                                description: "The date on which the certificate validity period begins, in RFC3339 format",
                            },
                            {
                                type: "string",
                                name: "serial_number",
                                description: "The body of the CA's certificate in PEM format",
                            },
                            {
                                type: "stringList",
                                name: "subject_alternative_names",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "subject_string",
                                description: "",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_container_registry",
			type: "data",
            displayName: "コンテナレジストリ",
            category: "lab",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "access_level",
                        description: "The level of access that allow to users. This will be one of [`readwrite`/`readonly`/`none`]",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the ContainerRegistry",
                    },
                    {
                        type: "string",
                        name: "fqdn",
                        description: "The FQDN for accessing the container registry. FQDN is built from `subdomain_label` + `.sakuracr.jp`",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the ContainerRegistry",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the ContainerRegistry",
                    },
                    {
                        type: "string",
                        name: "subdomain_label",
                        description: "The label at the lowest of the FQDN used when be accessed from users",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the ContainerRegistry",
                    },
                    {
                        type: "block",
                        name: "user",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "virtual_domain",
                        description: "The alias for accessing the container registry",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "user",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "name",
                                description: "The user name used to authenticate remote access",
                            },
                            {
                                type: "string",
                                name: "permission",
                                description: "The level of access that allow to the user. This will be one of [`all`/`readwrite`/`readonly`]",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_database",
			type: "data",
            displayName: "データベース",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Database is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "block",
                        name: "backup",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "database_type",
                        description: "The type of the database. This will be one of [`mariadb`/`postgres`]",
                    },
                    {
                        type: "string",
                        name: "database_version",
                        description: "The version of the database",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Database",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Database",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Database",
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                    },
                    {
                        type: "map",
                        name: "parameters",
                        description: "The map for setting RDBMS-specific parameters. Valid keys can be found with the `usacloud database list-parameters` command",
                    },
                    {
                        type: "string",
                        name: "password",
                        description: "The password of default user on the database",
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the Database. This will be one of [`10g`/`30g`/`90g`/`240g`/`500g`/`1t`]",
                    },
                    {
                        type: "string",
                        name: "replica_password",
                        description: "The password of user that processing a replication",
                    },
                    {
                        type: "string",
                        name: "replica_user",
                        description: "The name of user that processing a replication",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Database",
                    },
                    {
                        type: "string",
                        name: "username",
                        description: "The name of default user on the database",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "backup",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "time",
                                description: "The time to take backup. This will be formatted with `HH:mm`",
                            },
                            {
                                type: "stringList",
                                name: "weekdays",
                                description: "The list of name of weekday that doing backup. This will be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by Database",
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address assigned to the Database",
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the Database",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The number of the listening port",
                            },
                            {
                                type: "stringList",
                                name: "source_ranges",
                                description: "The range of source IP addresses that allow to access to the Database via network",
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch connected from the Database",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_disk",
			type: "data",
            displayName: "ディスク",
            category: "storage",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Disk is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "connector",
                        description: "The name of the disk connector. This will be one of [`virtio`/`ide`]",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Disk",
                    },
                    {
                        type: "string",
                        name: "encryption_algorithm",
                        description: "The disk encryption algorithm. This must be one of [`none`/`aes256_xts`]",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Disk",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Disk",
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the Disk. This will be one of [`ssd`/`hdd`]",
                    },
                    {
                        type: "string",
                        name: "server_id",
                        description: "The id of the Server connected to the Disk",
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of Disk in GiB",
                    },
                    {
                        type: "string",
                        name: "source_archive_id",
                        description: "The id of the source archive",
                    },
                    {
                        type: "string",
                        name: "source_disk_id",
                        description: "The id of the source disk",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Disk",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_dns",
			type: "data",
            displayName: "DNSゾーン",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the DNS",
                    },
                    {
                        type: "stringList",
                        name: "dns_servers",
                        description: "A list of IP address of DNS server that manage this zone",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the DNS",
                    },
                    {
                        type: "block",
                        name: "record",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the DNS",
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of managed domain",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "record",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the DNS Record",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The number of port",
                            },
                            {
                                type: "number",
                                name: "priority",
                                description: "The priority of target DNS Record",
                            },
                            {
                                type: "number",
                                name: "ttl",
                                description: "The number of the TTL",
                            },
                            {
                                type: "string",
                                name: "type",
                                description: "The type of DNS Record. This will be one of [`A`/`AAAA`/`ALIAS`/`CNAME`/`NS`/`MX`/`TXT`/`SRV`/`CAA`/`HTTPS`/`SVCB`/`PTR`]",
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "The value of the DNS Record",
                            },
                            {
                                type: "number",
                                name: "weight",
                                description: "The weight of target DNS Record",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_enhanced_db",
			type: "data",
            displayName: "エンハンスドデータベース(TiDB)",
            category: "lab",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "allowed_networks",
                        description: "A list of CIDR blocks allowed to connect",
                    },
                    {
                        type: "string",
                        name: "database_name",
                        description: "The name of database",
                    },
                    {
                        type: "string",
                        name: "database_type",
                        description: "The type of database",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the EnhancedDB",
                    },
                    {
                        type: "string",
                        name: "hostname",
                        description: "The name of database host. This will be built from `database_name` + `tidb-is1.db.sakurausercontent.com`",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the EnhancedDB",
                    },
                    {
                        type: "number",
                        name: "max_connections",
                        description: "The value of max connections setting",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the EnhancedDB",
                    },
                    {
                        type: "string",
                        name: "region",
                        description: "The region name",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the EnhancedDB",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_esme",
			type: "data",
            displayName: "2要素認証SMS",
            category: "sms",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the ESME",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the ESME",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the ESME",
                    },
                    {
                        type: "string",
                        name: "send_message_with_generated_otp_api_url",
                        description: "The API URL for send SMS with generated OTP",
                    },
                    {
                        type: "string",
                        name: "send_message_with_inputted_otp_api_url",
                        description: "The API URL for send SMS with inputted OTP",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the ESME",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_gslb",
			type: "data",
            displayName: "GSLB",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the GSLB",
                    },
                    {
                        type: "string",
                        name: "fqdn",
                        description: "The FQDN for accessing to the GSLB. This is typically used as value of CNAME record",
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the GSLB",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the GSLB",
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "sorry_server",
                        description: "The IP address of the SorryServer. This will be used when all servers are down",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the GSLB",
                    },
                    {
                        type: "boolean",
                        name: "weighted",
                        description: "The flag to enable weighted load-balancing",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "delay_loop",
                                description: "The interval in seconds between checks",
                            },
                            {
                                type: "string",
                                name: "host_header",
                                description: "The value of host header send when checking by HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP/HTTPS",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number used when checking by TCP/HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This will be one of [`http`/`https`/`tcp`/`ping`]",
                            },
                            {
                                type: "string",
                                name: "status",
                                description: "The response-code to expect when checking by HTTP/HTTPS",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        attributes: [
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable as destination of load balancing",
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the server",
                            },
                            {
                                type: "number",
                                name: "weight",
                                description: "The weight used when weighted load balancing is enabled",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_icon",
			type: "data",
            displayName: "アイコン",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Icon",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Icon",
                    },
                    {
                        type: "string",
                        name: "url",
                        description: "The URL for getting the icon's raw data",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_internet",
			type: "data",
            displayName: "スイッチ+ルータ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Switch+Router is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "number",
                        name: "band_width",
                        description: "The bandwidth of the network connected to the Internet in Mbps",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Switch+Router",
                    },
                    {
                        type: "boolean",
                        name: "enable_ipv6",
                        description: "The flag to enable IPv6",
                    },
                    {
                        type: "string",
                        name: "gateway",
                        description: "The IP address of the gateway used by Switch+Router",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Switch+Router",
                    },
                    {
                        type: "stringList",
                        name: "ip_addresses",
                        description: "A list of assigned global address to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "ipv6_network_address",
                        description: "The IPv6 network address assigned to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "ipv6_prefix",
                        description: "The network prefix of assigned IPv6 addresses to the Switch+Router",
                    },
                    {
                        type: "number",
                        name: "ipv6_prefix_len",
                        description: "The bit length of IPv6 network prefix",
                    },
                    {
                        type: "string",
                        name: "max_ip_address",
                        description: "Maximum IP address in assigned global addresses to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "min_ip_address",
                        description: "Minimum IP address in assigned global addresses to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Switch+Router",
                    },
                    {
                        type: "number",
                        name: "netmask",
                        description: "The bit length of the subnet assigned to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "network_address",
                        description: "The IPv4 network address assigned to the Switch+Router",
                    },
                    {
                        type: "stringList",
                        name: "server_ids",
                        description: "A list of the ID of Servers connected to the Switch+Router",
                    },
                    {
                        type: "string",
                        name: "switch_id",
                        description: "The id of the switch connected from the Switch+Router",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Switch+Router",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_load_balancer",
			type: "data",
            displayName: "ロードバランサ",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the LoadBalancer is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the LoadBalancer",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the LoadBalancer",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the LoadBalancer",
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the LoadBalancer. This will be one of [`standard`/`highspec`]",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the LoadBalancer",
                    },
                    {
                        type: "block",
                        name: "vip",
                        description: "",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by LoadBalancer",
                            },
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "The list of IP address assigned to the LoadBalancer",
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the LoadBalancer",
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch connected from the LoadBalancer",
                            },
                            {
                                type: "number",
                                name: "vrid",
                                description: "The Virtual Router Identifier",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "vip",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "delay_loop",
                                description: "The interval in seconds between checks",
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the VIP",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The target port number for load-balancing",
                            },
                            {
                                type: "block",
                                name: "server",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "sorry_server",
                                description: "The IP address of the SorryServer. This will be used when all servers under this VIP are down",
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        attributes: [
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable as destination of load balancing",
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the destination server",
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This will be one of [`http`/`https`/`tcp`/`ping`]",
                            },
                            {
                                type: "string",
                                name: "status",
                                description: "The response code to expect when checking by HTTP/HTTPS",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_local_router",
			type: "data",
            displayName: "ローカルルータ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the LocalRouter",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the LocalRouter",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the LocalRouter",
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "peer",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "secret_keys",
                        description: "A list of secret key used for peering from other LocalRouters",
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "switch",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the LocalRouter",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        attributes: [
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "The list of IP address assigned to the LocalRouter",
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the LocalRouter",
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address",
                            },
                            {
                                type: "number",
                                name: "vrid",
                                description: "The Virtual Router Identifier",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "peer",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the LocalRouter",
                            },
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable the LocalRouter",
                            },
                            {
                                type: "string",
                                name: "peer_id",
                                description: "The ID of the peer LocalRouter",
                            },
                            {
                                type: "string",
                                name: "secret_key",
                                description: "The secret key of the peer LocalRouter",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "next_hop",
                                description: "The IP address of the next hop",
                            },
                            {
                                type: "string",
                                name: "prefix",
                                description: "The CIDR block of destination",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "switch",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "category",
                                description: "The category name of connected services (e.g. `cloud`, `vps`)",
                            },
                            {
                                type: "string",
                                name: "code",
                                description: "The resource ID of the Switch",
                            },
                            {
                                type: "string",
                                name: "zone_id",
                                description: "The id of the Zone",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_nfs",
			type: "data",
            displayName: "NFS",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the NFS is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the NFS",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the NFS",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the NFS",
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the NFS. This will be one of [`hdd`/`ssd`]",
                    },
                    {
                        type: "number",
                        name: "size",
                        description: "The size of NFS in GiB",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the NFS",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "gateway",
                                description: "The IP address of the gateway used by NFS",
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address assigned to the NFS",
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the NFS",
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch connected from the NFS",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_note",
			type: "data",
            displayName: "スタートアップスクリプト",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "class",
                        description: "The class of the Note. This will be one of [`shell`/`yaml_cloud_config`]",
                    },
                    {
                        type: "string",
                        name: "content",
                        description: "The content of the Note",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Note",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Note",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Note",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Note",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_packet_filter",
			type: "data",
            displayName: "パケットフィルタ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the PacketFilter is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the PacketFilter",
                    },
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the PacketFilter",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        attributes: [
                            {
                                type: "boolean",
                                name: "allow",
                                description: "The flag to allow the packet through the filter",
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the expression",
                            },
                            {
                                type: "string",
                                name: "destination_port",
                                description: "A destination port number or port range used for filtering (e.g. `1024`, `1024-2048`)",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for filtering. This will be one of [`http`/`https`/`tcp`/`udp`/`icmp`/`fragment`/`ip`]",
                            },
                            {
                                type: "string",
                                name: "source_network",
                                description: "A source IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                            },
                            {
                                type: "string",
                                name: "source_port",
                                description: "A source port number or port range used for filtering (e.g. `1024`, `1024-2048`)",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_private_host",
			type: "data",
            displayName: "専有ホスト",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the PrivateHost is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "number",
                        name: "assigned_core",
                        description: "The total number of CPUs assigned to servers on the private host",
                    },
                    {
                        type: "number",
                        name: "assigned_memory",
                        description: "The total size of memory assigned to servers on the private host",
                    },
                    {
                        type: "string",
                        name: "class",
                        description: "The class of the PrivateHost. This will be one of [`dynamic`/`ms_windows`]",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the PrivateHost",
                    },
                    {
                        type: "string",
                        name: "hostname",
                        description: "The hostname of the private host",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the PrivateHost",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the PrivateHost",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the PrivateHost",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_proxylb",
			type: "data",
            displayName: "エンハンスドLB",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "backend_http_keep_alive",
                        description: "Mode of http keep-alive with backend",
                    },
                    {
                        type: "block",
                        name: "bind_port",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the ProxyLB",
                    },
                    {
                        type: "string",
                        name: "fqdn",
                        description: "The FQDN for accessing to the ProxyLB. This is typically used as value of CNAME record",
                    },
                    {
                        type: "boolean",
                        name: "gzip",
                        description: "The flag to enable gzip compression",
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the ProxyLB",
                    },
                    {
                        type: "block",
                        name: "letsencrypt",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the ProxyLB",
                    },
                    {
                        type: "number",
                        name: "plan",
                        description: "The plan name of the ProxyLB. This will be one of [`100`/`500`/`1000`/`5000`/`10000`/`50000`/`100000`/`400000`]",
                    },
                    {
                        type: "stringList",
                        name: "proxy_networks",
                        description: "A list of CIDR block used by the ProxyLB to access the server",
                    },
                    {
                        type: "boolean",
                        name: "proxy_protocol",
                        description: "The flag to enable proxy protocol v2",
                    },
                    {
                        type: "string",
                        name: "region",
                        description: "The name of region that the proxy LB is in. This will be one of [`tk1`/`is1`/`anycast`]",
                    },
                    {
                        type: "block",
                        name: "rule",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "sorry_server",
                        description: "",
                    },
                    {
                        type: "boolean",
                        name: "sticky_session",
                        description: "The flag to enable sticky session",
                    },
                    {
                        type: "block",
                        name: "syslog",
                        description: "",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the ProxyLB",
                    },
                    {
                        type: "number",
                        name: "timeout",
                        description: "The timeout duration in seconds",
                    },
                    {
                        type: "string",
                        name: "vip",
                        description: "The virtual IP address assigned to the ProxyLB",
                    },
                    {
                        type: "boolean",
                        name: "vip_failover",
                        description: "The flag to enable VIP fail-over",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "rule",
                        description: "",
                        required: false,
                        optional: false,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "request_header_name",
                                description: "The header name that the client will send when making a request",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "request_header_value",
                                description: "The condition for the value of the request header specified by the request header name",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "request_header_value_ignore_case",
                                description: "Boolean value representing whether the request header value ignores case",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "boolean",
                                name: "request_header_value_not_match",
                                description: "Boolean value representing whether to apply the rules when the request header value conditions are met or when the conditions do not match",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "bind_port",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "port",
                                description: "The number of listening port",
                            },
                            {
                                type: "string",
                                name: "proxy_mode",
                                description: "The proxy mode. This will be one of [`http`/`https`/`tcp`]",
                            },
                            {
                                type: "boolean",
                                name: "redirect_to_https",
                                description: "The flag to enable redirection from http to https. This flag is used only when `proxy_mode` is `http`",
                            },
                            {
                                type: "block",
                                name: "response_header",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "ssl_policy",
                                description: "The ssl policy",
                            },
                            {
                                type: "boolean",
                                name: "support_http2",
                                description: "The flag to enable HTTP/2. This flag is used only when `proxy_mode` is `https`",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "response_header",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "header",
                                description: "The field name of HTTP header added to response by the ProxyLB",
                            },
                            {
                                type: "string",
                                name: "value",
                                description: "The field value of HTTP header added to response by the ProxyLB",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "certificate",
                        description: "",
                        attributes: [
                            {
                                type: "block",
                                name: "additional_certificate",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "common_name",
                                description: "The common name of the certificate",
                            },
                            {
                                type: "string",
                                name: "intermediate_cert",
                                description: "The intermediate certificate for a server",
                            },
                            {
                                type: "string",
                                name: "private_key",
                                description: "The private key for a server",
                            },
                            {
                                type: "string",
                                name: "server_cert",
                                description: "The certificate for a server",
                            },
                            {
                                type: "string",
                                name: "subject_alt_names",
                                description: "The subject alternative names of the certificate",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "additional_certificate",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "intermediate_cert",
                                description: "The intermediate certificate for a server",
                            },
                            {
                                type: "string",
                                name: "private_key",
                                description: "The private key for a server",
                            },
                            {
                                type: "string",
                                name: "server_cert",
                                description: "The certificate for a server",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "delay_loop",
                                description: "The interval in seconds between checks",
                            },
                            {
                                type: "string",
                                name: "host_header",
                                description: "The value of host header send when checking by HTTP",
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number used when checking by TCP",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This will be one of [`http`/`tcp`]",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "letsencrypt",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "common_name",
                                description: "The common name of the certificate",
                            },
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to accept the current Let's Encrypt terms of service(see: https://letsencrypt.org/repository/). This must be set `true` explicitly",
                            },
                            {
                                type: "stringList",
                                name: "subject_alt_names",
                                description: "The subject alternative names of the certificate",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "rule",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "action",
                                description: "The type of action to be performed when requests matches the rule. This will be one of [`forward`/`redirect`/`fixed`]",
                            },
                            {
                                type: "string",
                                name: "fixed_content_type",
                                description: "Content-Type header value for fixed response sent when requests matches the rule. This will be one of [`text/plain`/`text/html`/`application/javascript`/`application/json`]",
                            },
                            {
                                type: "string",
                                name: "fixed_message_body",
                                description: "Content body for fixed response sent when requests matches the rule",
                            },
                            {
                                type: "string",
                                name: "fixed_status_code",
                                description: "HTTP status code for fixed response sent when requests matches the rule. This will be one of [`200`/`403`/`503`]",
                            },
                            {
                                type: "string",
                                name: "group",
                                description: "The name of load balancing group. When proxyLB received request which matched to `host` and `path`, proxyLB forwards the request to servers that having same group name",
                            },
                            {
                                type: "string",
                                name: "host",
                                description: "The value of HTTP host header that is used as condition of rule-based balancing",
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The request path that is used as condition of rule-based balancing",
                            },
                            {
                                type: "string",
                                name: "redirect_location",
                                description: "The URL to redirect to when the request matches the rule. see https://manual.sakura.ad.jp/cloud/appliance/enhanced-lb/#enhanced-lb-rule for details",
                            },
                            {
                                type: "string",
                                name: "redirect_status_code",
                                description: "HTTP status code for redirects sent when requests matches the rule. This will be one of [`301`/`302`]",
                            },
                            {
                                type: "string",
                                name: "source_ips",
                                description: "IP address or CIDR block to which the rule will be applied",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "server",
                        description: "",
                        attributes: [
                            {
                                type: "boolean",
                                name: "enabled",
                                description: "The flag to enable as destination of load balancing",
                            },
                            {
                                type: "string",
                                name: "group",
                                description: "The name of load balancing group. This is used when using rule-based load balancing",
                            },
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the destination server",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number of the destination server",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "sorry_server",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address of the SorryServer. This will be used when all servers are down",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The port number of the SorryServer. This will be used when all servers are down",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "syslog",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "port",
                                description: "The number of syslog port",
                            },
                            {
                                type: "string",
                                name: "server",
                                description: "The address of syslog server",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_server",
			type: "data",
            displayName: "サーバ",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Server is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "cdrom_id",
                        description: "The id of the CD-ROM attached to the server",
                    },
                    {
                        type: "string",
                        name: "commitment",
                        description: "The policy of how to allocate virtual CPUs to the server. This will be one of [`standard`/`dedicatedcpu`]",
                    },
                    {
                        type: "number",
                        name: "core",
                        description: "The number of virtual CPUs",
                    },
                    {
                        type: "string",
                        name: "cpu_model",
                        description: "The model of cpu",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Server",
                    },
                    {
                        type: "stringList",
                        name: "disks",
                        description: "A list of disk id connected to the server",
                    },
                    {
                        type: "stringList",
                        name: "dns_servers",
                        description: "A list of IP address of DNS server in the zone",
                    },
                    {
                        type: "string",
                        name: "gateway",
                        description: "The IP address of the gateway used by Server",
                    },
                    {
                        type: "number",
                        name: "gpu",
                        description: "The number of GPUs",
                    },
                    {
                        type: "string",
                        name: "hostname",
                        description: "The hostname of the Server",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Server",
                    },
                    {
                        type: "string",
                        name: "interface_driver",
                        description: "The driver name of network interface. This will be one of [`virtio`/`e1000`]",
                    },
                    {
                        type: "string",
                        name: "ip_address",
                        description: "The IP address assigned to the Server",
                    },
                    {
                        type: "number",
                        name: "memory",
                        description: "The size of memory in GiB",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Server",
                    },
                    {
                        type: "number",
                        name: "netmask",
                        description: "The bit length of the subnet assigned to the Server",
                    },
                    {
                        type: "string",
                        name: "network_address",
                        description: "The network address which the `ip_address` belongs",
                    },
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "private_host_id",
                        description: "The id of the private host which the server is assigned",
                    },
                    {
                        type: "string",
                        name: "private_host_name",
                        description: "The name of the private host which the server is assigned",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Server",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "network_interface",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "mac_address",
                                description: "The MAC address",
                            },
                            {
                                type: "string",
                                name: "packet_filter_id",
                                description: "The id of the packet filter attached to the network interface",
                            },
                            {
                                type: "string",
                                name: "upstream",
                                description: "The upstream type or upstream switch id. This will be one of [`shared`/`disconnect`/`<switch id>`]",
                            },
                            {
                                type: "string",
                                name: "user_ip_address",
                                description: "The IP address for only display. This value doesn't affect actual NIC settings",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_server_vnc_info",
			type: "data",
            displayName: "サーバVNC接続情報",
            category: "compute",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "server_id",
                        description: "The id of the Server",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Server VNC Information is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "host",
                        description: "The host name for connecting by VNC",
                    },
                    {
                        type: "string",
                        name: "password",
                        description: "The password for connecting by VNC",
                    },
                    {
                        type: "number",
                        name: "port",
                        description: "The port number for connecting by VNC",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_simple_monitor",
			type: "data",
            displayName: "シンプル監視",
            category: "global",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "number",
                        name: "delay_loop",
                        description: "The interval in seconds between checks",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the SimpleMonitor",
                    },
                    {
                        type: "boolean",
                        name: "enabled",
                        description: "The flag to enable monitoring by the simple monitor",
                    },
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the SimpleMonitor",
                    },
                    {
                        type: "number",
                        name: "max_check_attempts",
                        description: "The number of retry",
                    },
                    {
                        type: "boolean",
                        name: "notify_email_enabled",
                        description: "The flag to enable notification by email",
                    },
                    {
                        type: "boolean",
                        name: "notify_email_html",
                        description: "The flag to enable HTML format instead of text format",
                    },
                    {
                        type: "number",
                        name: "notify_interval",
                        description: "The interval in hours between notification",
                    },
                    {
                        type: "boolean",
                        name: "notify_slack_enabled",
                        description: "The flag to enable notification by slack/discord",
                    },
                    {
                        type: "string",
                        name: "notify_slack_webhook",
                        description: "The webhook URL for sending notification by slack/discord",
                    },
                    {
                        type: "number",
                        name: "retry_interval",
                        description: "The interval in seconds between retries",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the SimpleMonitor",
                    },
                    {
                        type: "string",
                        name: "target",
                        description: "The monitoring target of the simple monitor. This will be IP address or FQDN",
                    },
                    {
                        type: "number",
                        name: "timeout",
                        description: "The timeout in seconds for monitoring",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "health_check",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "community",
                                description: "The SNMP community string used when checking by SNMP",
                            },
                            {
                                type: "string",
                                name: "contains_string",
                                description: "The string that should be included in the response body when checking for HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "excepcted_data",
                                description: "The expected value used when checking by DNS",
                            },
                            {
                                type: "string",
                                name: "ftps",
                                description: "The methods of invoking security for monitoring with FTPS. This will be one of [``/`implicit`/`explicit`]",
                            },
                            {
                                type: "string",
                                name: "host_header",
                                description: "The value of host header send when checking by HTTP/HTTPS",
                            },
                            {
                                type: "boolean",
                                name: "http2",
                                description: "The flag to enable HTTP/2 when checking by HTTPS",
                            },
                            {
                                type: "string",
                                name: "oid",
                                description: "The SNMP OID used when checking by SNMP",
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The password for basic auth used when checking by HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "path",
                                description: "The path used when checking by HTTP/HTTPS",
                            },
                            {
                                type: "number",
                                name: "port",
                                description: "The target port number",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for health checks. This will be one of [`http`/`https`/`ping`/`tcp`/`dns`/`ssh`/`smtp`/`pop3`/`snmp`/`sslcertificate`/`ftp`]",
                            },
                            {
                                type: "string",
                                name: "qname",
                                description: "The FQDN used when checking by DNS",
                            },
                            {
                                type: "number",
                                name: "remaining_days",
                                description: "The number of remaining days until certificate expiration used when checking SSL certificates",
                            },
                            {
                                type: "boolean",
                                name: "sni",
                                description: "The flag to enable SNI when checking by HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "snmp_version",
                                description: "The SNMP version used when checking by SNMP",
                            },
                            {
                                type: "number",
                                name: "status",
                                description: "The response-code to expect when checking by HTTP/HTTPS",
                            },
                            {
                                type: "string",
                                name: "username",
                                description: "The user name for basic auth used when checking by HTTP/HTTPS",
                            },
                            {
                                type: "boolean",
                                name: "verify_sni",
                                description: "The flag to enable hostname verification for SNI",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_ssh_key",
			type: "data",
            displayName: "SSH公開鍵",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the SSHKey",
                    },
                    {
                        type: "string",
                        name: "fingerprint",
                        description: "The fingerprint of public key",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the SSHKey",
                    },
                    {
                        type: "string",
                        name: "public_key",
                        description: "The value of public key",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_subnet",
			type: "data",
            displayName: "サブネット",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "number",
                        name: "index",
                        description: "The index of the subnet in assigned to the Switch+Router",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "internet_id",
                        description: "The id of the switch+router resource that the Subnet belongs",
                        required: true,
                        optional: false,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Subnet is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "stringList",
                        name: "ip_addresses",
                        description: "A list of assigned global address to the Subnet",
                    },
                    {
                        type: "string",
                        name: "max_ip_address",
                        description: "Maximum IP address in assigned global addresses to the Subnet",
                    },
                    {
                        type: "string",
                        name: "min_ip_address",
                        description: "Minimum IP address in assigned global addresses to the Subnet",
                    },
                    {
                        type: "number",
                        name: "netmask",
                        description: "The bit length of the subnet assigned to the Subnet",
                    },
                    {
                        type: "string",
                        name: "network_address",
                        description: "The IPv4 network address assigned to the Subnet",
                    },
                    {
                        type: "string",
                        name: "next_hop",
                        description: "The ip address of the next-hop at the Subnet",
                    },
                    {
                        type: "string",
                        name: "switch_id",
                        description: "The id of the switch connected from the Subnet",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_switch",
			type: "data",
            displayName: "スイッチ",
            category: "network",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the Switch is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "bridge_id",
                        description: "The bridge id attached to the Switch",
                    },
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the Switch",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the Switch",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the Switch",
                    },
                    {
                        type: "stringList",
                        name: "server_ids",
                        description: "A list of server id connected to the Switch",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the Switch",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_vpc_router",
			type: "data",
            displayName: "VPCルータ",
            category: "appliance",
            schema: {
                arguments: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 1,
                        minItems: 1,
                    },
                    {
                        type: "string",
                        name: "zone",
                        description: "The name of zone that the VPCRouter is in (e.g. `is1a`, `tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: true,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the VPCRouter",
                    },
                    {
                        type: "block",
                        name: "dhcp_server",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "dhcp_static_mapping",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "dns_forwarding",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "firewall",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "icon_id",
                        description: "The icon id attached to the VPCRouter",
                    },
                    {
                        type: "boolean",
                        name: "internet_connection",
                        description: "The flag to enable connecting to the Internet from the VPC Router",
                    },
                    {
                        type: "block",
                        name: "l2tp",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "The id of the switch connected from the VPCRouter",
                    },
                    {
                        type: "string",
                        name: "plan",
                        description: "The plan name of the VPCRouter. This will be one of [`standard`/`premium`/`highspec`/`highspec4000`]",
                    },
                    {
                        type: "block",
                        name: "port_forwarding",
                        description: "A list of `port_forwarding` blocks as defined below. This represents a `Reverse NAT`",
                    },
                    {
                        type: "block",
                        name: "pptp",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "private_network_interface",
                        description: "A list of additional network interface setting. This doesn't include primary network interface setting",
                    },
                    {
                        type: "string",
                        name: "public_ip",
                        description: "The public ip address of the VPC Router",
                    },
                    {
                        type: "number",
                        name: "public_netmask",
                        description: "The bit length of the subnet to assign to the public network interface",
                    },
                    {
                        type: "block",
                        name: "public_network_interface",
                        description: "A list of additional network interface setting. This doesn't include primary network interface setting",
                    },
                    {
                        type: "block",
                        name: "scheduled_maintenance",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn_parameter",
                        description: "",
                    },
                    {
                        type: "block",
                        name: "static_nat",
                        description: "A list of `static_nat` blocks as defined below. This represents a `1:1 NAT`, doing static mapping to both send/receive to/from the Internet. This is only used when `plan` is not `standard`",
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "syslog_host",
                        description: "The ip address of the syslog host to which the VPC Router sends logs",
                    },
                    {
                        type: "stringList",
                        name: "tags",
                        description: "Any tags assigned to the VPCRouter",
                    },
                    {
                        type: "block",
                        name: "user",
                        description: "",
                    },
                    {
                        type: "number",
                        name: "version",
                        description: "The version of the VPC Router",
                    },
                    {
                        type: "block",
                        name: "wire_guard",
                        description: "",
                    },
                ],
                argumentBlocks: [
                    {
                        type: "block",
                        name: "filter",
                        description: "One or more values used for filtering, as defined below",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 1,
                        minItems: 1,
                        arguments: [
                            {
                                type: "block",
                                name: "condition",
                                description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "id",
                                description: "The resource id on SakuraCloud used for filtering",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "names",
                                description: "The resource names on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "tags",
                                description: "The resource tags on SakuraCloud used for filtering. If multiple values ​​are specified, they combined as AND condition",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "condition",
                        description: "One or more name/values pairs used for filtering. There are several valid keys, for a full reference, check out finding section in the [SakuraCloud API reference](https://developer.sakura.ad.jp/cloud/api/1.1/)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        maxItems: 0,
                        minItems: 0,
                        arguments: [
                            {
                                type: "string",
                                name: "name",
                                description: "The name of the target field. This value is case-sensitive",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "string",
                                name: "operator",
                                description: "The filtering operator. This must be one of following:  `partial_match_and`/`exact_match_or`",
                                required: false,
                                optional: true,
                                forceNew: false,
                                default: "partial_match_and",
                                maxItems: 0,
                                minItems: 0,
                            },
                            {
                                type: "stringList",
                                name: "values",
                                description: "The values of the condition. If multiple values ​​are specified, they combined as AND condition",
                                required: true,
                                optional: false,
                                forceNew: false,
                                default: "",
                                maxItems: 0,
                                minItems: 0,
                            },
                        ],
                    },
                ],
                attributeBlocks: [
                    {
                        type: "block",
                        name: "dhcp_server",
                        description: "",
                        attributes: [
                            {
                                type: "stringList",
                                name: "dns_servers",
                                description: "A list of IP address of DNS server to assign to DHCP client",
                            },
                            {
                                type: "number",
                                name: "interface_index",
                                description: "The index of the network interface on which to enable the DHCP service. This will be between `1`-`7`",
                            },
                            {
                                type: "string",
                                name: "range_start",
                                description: "The start value of IP address range to assign to DHCP client",
                            },
                            {
                                type: "string",
                                name: "range_stop",
                                description: "The end value of IP address range to assign to DHCP client",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "dhcp_static_mapping",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The static IP address to assign to DHCP client",
                            },
                            {
                                type: "string",
                                name: "mac_address",
                                description: "The source MAC address of static mapping",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "dns_forwarding",
                        description: "",
                        attributes: [
                            {
                                type: "stringList",
                                name: "dns_servers",
                                description: "A list of IP address of DNS server to forward to",
                            },
                            {
                                type: "number",
                                name: "interface_index",
                                description: "The index of the network interface on which to enable the DNS forwarding service",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "firewall",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "direction",
                                description: "The direction to apply the firewall. This will be one of [`send`/`receive`]",
                            },
                            {
                                type: "block",
                                name: "expression",
                                description: "",
                            },
                            {
                                type: "number",
                                name: "interface_index",
                                description: "The index of the network interface on which to enable filtering. This will be between `0`-`7`",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "expression",
                        description: "",
                        attributes: [
                            {
                                type: "boolean",
                                name: "allow",
                                description: "The flag to allow the packet through the filter",
                            },
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the expression",
                            },
                            {
                                type: "string",
                                name: "destination_network",
                                description: "A destination IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                            },
                            {
                                type: "string",
                                name: "destination_port",
                                description: "A destination port number or port range used for filtering (e.g. `1024`, `1024-2048`). This is only used when `protocol` is `tcp` or `udp`",
                            },
                            {
                                type: "boolean",
                                name: "logging",
                                description: "The flag to enable packet logging when matching the expression",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for filtering. This will be one of [`tcp`/`udp`/`icmp`/`ip`]",
                            },
                            {
                                type: "string",
                                name: "source_network",
                                description: "A source IP address or CIDR block used for filtering (e.g. `192.0.2.1`, `192.0.2.0/24`)",
                            },
                            {
                                type: "string",
                                name: "source_port",
                                description: "A source port number or port range used for filtering (e.g. `1024`, `1024-2048`). This is only used when `protocol` is `tcp` or `udp`",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "l2tp",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "pre_shared_secret",
                                description: "The pre shared secret for L2TP/IPsec",
                            },
                            {
                                type: "string",
                                name: "range_start",
                                description: "The start value of IP address range to assign to L2TP/IPsec client",
                            },
                            {
                                type: "string",
                                name: "range_stop",
                                description: "The end value of IP address range to assign to L2TP/IPsec client",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "port_forwarding",
                        description: "A list of `port_forwarding` blocks as defined below. This represents a `Reverse NAT`",
                        attributes: [
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the port forwarding",
                            },
                            {
                                type: "string",
                                name: "private_ip",
                                description: "The destination ip address of the port forwarding",
                            },
                            {
                                type: "number",
                                name: "private_port",
                                description: "The destination port number of the port forwarding. This will be a port number on a private network",
                            },
                            {
                                type: "string",
                                name: "protocol",
                                description: "The protocol used for port forwarding. This will be one of [`tcp`/`udp`]",
                            },
                            {
                                type: "number",
                                name: "public_port",
                                description: "The source port number of the port forwarding. This will be a port number on a public network",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "pptp",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "range_start",
                                description: "The start value of IP address range to assign to PPTP client",
                            },
                            {
                                type: "string",
                                name: "range_stop",
                                description: "The end value of IP address range to assign to PPTP client",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "private_network_interface",
                        description: "A list of additional network interface setting. This doesn't include primary network interface setting",
                        attributes: [
                            {
                                type: "number",
                                name: "index",
                                description: "The index of the network interface. This will be between `1`-`7`",
                            },
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "A list of ip address assigned to the network interface. This will be only one value when `plan` is `standard`, two values otherwise",
                            },
                            {
                                type: "number",
                                name: "netmask",
                                description: "The bit length of the subnet assigned to the network interface",
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the connected switch",
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address assigned to the network interface. This is only used when `plan` is not `standard`",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "public_network_interface",
                        description: "A list of additional network interface setting. This doesn't include primary network interface setting",
                        attributes: [
                            {
                                type: "stringList",
                                name: "aliases",
                                description: "A list of ip alias assigned to the VPC Router. This is only used when `plan` is not `standard`",
                            },
                            {
                                type: "stringList",
                                name: "ip_addresses",
                                description: "The list of the IP address assigned to the VPC Router. This will be only one value when `plan` is `standard`, two values otherwise",
                            },
                            {
                                type: "string",
                                name: "switch_id",
                                description: "The id of the switch connected from the VPCRouter",
                            },
                            {
                                type: "string",
                                name: "vip",
                                description: "The virtual IP address of the VPC Router. This is only used when `plan` is not `standard`",
                            },
                            {
                                type: "number",
                                name: "vrid",
                                description: "The Virtual Router Identifier. This is only used when `plan` is not `standard`",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "scheduled_maintenance",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "day_of_week",
                                description: "The value must be in [`sun`/`mon`/`tue`/`wed`/`thu`/`fri`/`sat`]",
                            },
                            {
                                type: "number",
                                name: "hour",
                                description: "The time to start maintenance",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn",
                        description: "",
                        attributes: [
                            {
                                type: "stringList",
                                name: "local_prefix",
                                description: "A list of CIDR block of the network under the VPC Router",
                            },
                            {
                                type: "string",
                                name: "peer",
                                description: "The IP address of the opposing appliance connected to the VPC Router",
                            },
                            {
                                type: "string",
                                name: "pre_shared_secret",
                                description: "The pre shared secret for the VPN",
                            },
                            {
                                type: "string",
                                name: "remote_id",
                                description: "The id of the opposing appliance connected to the VPC Router. This is typically set same as value of `peer`",
                            },
                            {
                                type: "stringList",
                                name: "routes",
                                description: "A list of CIDR block of VPN connected networks",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "site_to_site_vpn_parameter",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "dh_group",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "encryption_algo",
                                description: "",
                            },
                            {
                                type: "block",
                                name: "esp",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "hash_algo",
                                description: "",
                            },
                            {
                                type: "block",
                                name: "ike",
                                description: "",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "esp",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "lifetime",
                                description: "",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "ike",
                        description: "",
                        attributes: [
                            {
                                type: "block",
                                name: "dpd",
                                description: "",
                            },
                            {
                                type: "number",
                                name: "lifetime",
                                description: "",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "dpd",
                        description: "",
                        attributes: [
                            {
                                type: "number",
                                name: "interval",
                                description: "",
                            },
                            {
                                type: "number",
                                name: "timeout",
                                description: "",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_nat",
                        description: "A list of `static_nat` blocks as defined below. This represents a `1:1 NAT`, doing static mapping to both send/receive to/from the Internet. This is only used when `plan` is not `standard`",
                        attributes: [
                            {
                                type: "string",
                                name: "description",
                                description: "The description of the static NAT",
                            },
                            {
                                type: "string",
                                name: "private_ip",
                                description: "The private IP address used for the static NAT",
                            },
                            {
                                type: "string",
                                name: "public_ip",
                                description: "The public IP address used for the static NAT",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "static_route",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "next_hop",
                                description: "The IP address of the next hop",
                            },
                            {
                                type: "string",
                                name: "prefix",
                                description: "The CIDR block of destination",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "user",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "name",
                                description: "The user name used to authenticate remote access",
                            },
                            {
                                type: "string",
                                name: "password",
                                description: "The password used to authenticate remote access",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "wire_guard",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address for WireGuard server",
                            },
                            {
                                type: "block",
                                name: "peer",
                                description: "",
                            },
                            {
                                type: "string",
                                name: "public_key",
                                description: "the public key of the WireGuard server",
                            },
                        ],
                    },
                    {
                        type: "block",
                        name: "peer",
                        description: "",
                        attributes: [
                            {
                                type: "string",
                                name: "ip_address",
                                description: "The IP address for peer",
                            },
                            {
                                type: "string",
                                name: "name",
                                description: "the of the peer",
                            },
                            {
                                type: "string",
                                name: "public_key",
                                description: "the public key of the WireGuard client",
                            },
                        ],
                    },
                ],
            },
        },
        {
            name: "sakuracloud_webaccel",
			type: "data",
            displayName: "ウェブアクセラレータ",
            category: "webaccel",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "domain",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                    {
                        type: "string",
                        name: "name",
                        description: "",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "cname_record_value",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "domain_type",
                        description: "",
                    },
                    {
                        type: "boolean",
                        name: "has_certificate",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "host_header",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "origin",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "site_id",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "status",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "subdomain",
                        description: "",
                    },
                    {
                        type: "string",
                        name: "txt_record_value",
                        description: "",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
        {
            name: "sakuracloud_zone",
			type: "data",
            displayName: "ゾーン",
            category: "misc",
            schema: {
                arguments: [
                    {
                        type: "string",
                        name: "name",
                        description: "The name of the zone (e.g. `is1a`,`tk1a`)",
                        required: false,
                        optional: true,
                        forceNew: false,
                        default: "",
                        maxItems: 0,
                        minItems: 0,
                    },
                ],
                attributes: [
                    {
                        type: "string",
                        name: "description",
                        description: "The description of the zone",
                    },
                    {
                        type: "stringList",
                        name: "dns_servers",
                        description: "A list of IP address of DNS server in the zone",
                    },
                    {
                        type: "string",
                        name: "region_id",
                        description: "The id of the region that the zone belongs",
                    },
                    {
                        type: "string",
                        name: "region_name",
                        description: "The name of the region that the zone belongs",
                    },
                    {
                        type: "string",
                        name: "zone_id",
                        description: "The id of the zone",
                    },
                ],
                argumentBlocks: [
                ],
                attributeBlocks: [
                ],
            },
        },
	],
};