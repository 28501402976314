import ComputerIcon from '@material-ui/icons/Computer';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import AppsIcon from '@material-ui/icons/Apps';
import PublicIcon from '@material-ui/icons/Public';
import SimCardIcon from '@material-ui/icons/SimCard';
import DescriptionIcon from '@material-ui/icons/Description';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import CloudIcon from '@material-ui/icons/Cloud';
import SmsIcon from '@material-ui/icons/Sms';
import Constants from "@sacloud/constants/constants";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {SvgIconTypeMap} from "@material-ui/core/SvgIcon/SvgIcon";

export interface categoryDef {
    name: string;
    displayName: string;
    order: number;
    ignore: boolean;
    icon: OverridableComponent<SvgIconTypeMap>;
}

// eslint-disable-next-line
export const categoryDef: categoryDef[] = [
    {name: "compute", displayName: "コンピュート", order: 10, ignore: false, icon: ComputerIcon},
    {name: "storage", displayName: "ストレージ", order: 20, ignore: false, icon: StorageIcon},
    {name: "network", displayName: "ネットワーク", order: 30, ignore: false, icon: SettingsEthernetIcon},
    {name: "appliance", displayName: "アプラインス", order: 40, ignore: false, icon: AppsIcon},
    {name: "global", displayName: "グローバル", order: 50, ignore: false, icon: PublicIcon},
    {name: "securemobile", displayName: "セキュアモバイル", order: 60, ignore: false, icon: SimCardIcon},
    {name: "objectstorage", displayName: "オブジェクトストレージ", order: 70, ignore: true, icon: DescriptionIcon},
    {name: "sms", displayName: "SMS", order: 80, ignore: false, icon: SmsIcon},
    {name: "lab", displayName: "Lab", order: 85, ignore: false, icon: GroupWorkIcon},
    {name: "webaccel", displayName: "ウェブアクセラレータ", order: 90, ignore: false, icon: CloudIcon},
    {name: "apprun", displayName: "AppRun", order: 95, ignore: false, icon: CloudIcon},
    {name: "misc", displayName: "その他", order: 100, ignore: false, icon: CloudIcon},
];

export interface resourceDef {
    name: string;
    image: string;
    order: number;
    ignore: boolean;

    // TODO あとで?を消す
    arguments?: argumentDef[];
    argumentBlocks?: argumentBlockDef[];
}

export interface argumentDef {
    name: string;
    order?: number;
    ignore: boolean;
    allowValues?: null | { value: string, text?: string }[];
    singleSelect?: boolean;
    referenceSourcePatterns?: RegExp[];
    suffix?: string; // e.g. unit -> GiB
}

export interface argumentBlockDef {
    name: string;
    arguments: argumentDef[];
}

const commonArguments = {
    name: {
        name: "name",
        order: 1,
        ignore: false,
    },
    description: {
        name: "description",
        order: 90,
        ignore: false,
    },
    tags: {
        name: "tags",
        order: 91,
        ignore: false,
        suffix: "",
    },
    iconId: {
        name: "icon_id",
        order: 92,
        ignore: false,
        referenceSourcePatterns: [/^(data\.)?sakuracloud_icon\..*\.id/],
    },
    zone: {
        name: "zone",
        order: 99,
        ignore: false,
        allowValues: [
            {value: "is1a", text: "石狩第1(is1a)"},
            {value: "is1b", text: "石狩第2(is1b)"},
            {value: "tk1a", text: "東京第1(tk1a)"},
            {value: "tk1v", text: "サンドボックス(tk1v)"},
        ],
        singleSelect: true,
        suffix: "",
    },
    weekdays: (order: number) => {
        return {
            name: "weekdays",
            order: order,
            ignore: false,
            allowValues: [
                {value: Constants.Backup.Weekdays.Sunday, text: "日"},
                {value: Constants.Backup.Weekdays.Monday, text: "月"},
                {value: Constants.Backup.Weekdays.Tuesday, text: "火"},
                {value: Constants.Backup.Weekdays.Wednesday, text: "水"},
                {value: Constants.Backup.Weekdays.Thursday, text: "木"},
                {value: Constants.Backup.Weekdays.Friday, text: "金"},
                {value: Constants.Backup.Weekdays.Saturday, text: "土"},
            ],
            singleSelect: false,
            suffix: "",
        }
    }
};

// eslint-disable-next-line
export const resourceDef: resourceDef[] = [
    // Compute
    {
        name: "sakuracloud_server",
        image: "server-cloud.png",
        order: 1,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "commitment",
                order: 20,
                allowValues: [
                    {value: Constants.Server.Commitments.Standard, text: "標準"},
                    {value: Constants.Server.Commitments.DedicatedCPU, text: "コア専有"},
                ],
                ignore: false,
            },
            {
                name: "core",
                order: 21,
                ignore: false,
            },
            {
                name: "memory",
                order: 22,
                ignore: false,
                suffix: "GiB",
            },
            {
                name: "interface_driver",
                order: 23,
                allowValues: [
                    {value: "virtio", text: "VirtIO"},
                    {value: "e1000", text: "e1000"},
                ],
                ignore: false,
            },
            {
                name: "network_interface",
                order: 30,
                ignore: false,
            },
            {
                name: "disks",
                order: 40,
                ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_disk\..*\.id/],
            },
            {
                name: "disk_edit_parameter",
                order: 41,
                ignore: false,
            },

            {
                name: "private_host_id",
                order: 50,
                ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_private_host\..*\.id/],
            },
            {
                name: "cdrom_id",
                order: 51,
                ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_cdrom\..*\.id/],
            },
            {
                name: "force_shutdown",
                order: 80,
                ignore: false,
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "network_interface",
                arguments: [
                    {
                        name: "upstream",
                        order: 10,
                        ignore: false, // TODO 候補値(shared/disconnect/スイッチのID)をどう扱うか
                    },
                    {
                        name: "packet_filter_id",
                        order: 20,
                        ignore: false,
                        referenceSourcePatterns: [/^(data\.)?sakuracloud_packet_filter\..*\.id/],
                    },
                    {
                        name: "user_ip_address",
                        order: 30,
                        ignore: false,
                    },
                ],
            },
            {
                name: "disk_edit_parameter",
                arguments: [
                    {
                        name: "hostname",
                        order: 10,
                        ignore: false,
                    },
                    {
                        name: "password",
                        order: 11,
                        ignore: false,
                    },
                    {
                        name: "disable_pw_auth",
                        order: 12,
                        ignore: false,
                    },
                    {
                        name: "ip_address",
                        order: 20,
                        ignore: false,
                    },
                    {
                        name: "netmask",
                        order: 21,
                        ignore: false,
                    },
                    {
                        name: "gateway",
                        order: 22,
                        ignore: false,
                    },
                    {
                        name: "change_partition_uuid",
                        order: 30,
                        ignore: false,
                    },
                    {
                        name: "enable_dhcp",
                        order: 31,
                        ignore: false,
                    },
                    {
                        name: "note_ids",
                        order: 40,
                        ignore: true,
                    },
                    {
                        name: "note",
                        order: 41,
                        ignore: false,
                    },
                    {
                        name: "ssh_key_ids",
                        order: 41,
                        ignore: false,
                    },
                ],
            },
            {
                name: "note",
                arguments: [
                    {
                        name: "id",
                        order: 10,
                        ignore: false,
                    },
                    {
                        name: "api_key_id",
                        order: 20,
                        ignore: false,
                        referenceSourcePatterns: [/^(data\.)?sakuracloud_packet_filter\..*\.id/],
                    },
                    {
                        name: "variables",
                        order: 30,
                        ignore: false,
                    },
                ],
            },
        ],
    },
    {
        name: "sakuracloud_private_host",
        image: "servers.png",
        order: 2,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "class",
                order: 10,
                ignore: false,
                allowValues: [
                    {value: "dynamic", text: "標準(dynamic)"},
                    {value: "ms_windows", text: "Windows(ms_windows)"},
                ],
                singleSelect: true,
                suffix: "",
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
    },

    {
        name: "sakuracloud_auto_scale",
        image: "servers.png",
        order: 3,
        ignore: false,
    },
    // Storage
    {
        name: "sakuracloud_disk",
        image: "HDD.png",
        order: 1,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "plan",
                order: 10,
                ignore: false,
                allowValues: [
                    {value: "ssd", text: "SSDプラン"},
                    {value: "hdd", text: "標準プラン"},
                ],
            },
            {
                name: "size",
                order: 11,
                ignore: false,
                allowValues: null,
                suffix: "GiB",
            },
            {
                name: "connector",
                order: 12,
                ignore: false,
                allowValues: [
                    {value: "virtio", text: "VirtIO"},
                    {value: "ide", text: "IDE"},
                ],
                singleSelect: true,
            },
            {
                name: "source_archive_id",
                order: 20,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_archive\..*\.id/],
                ignore: false,
            },
            {
                name: "source_disk_id",
                order: 21,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_disk\..*\.id/],
                ignore: false,
            },
            {
                name: "distant_from",
                order: 30,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_disk\..*\.id/],
                ignore: false,
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
    },
    {
        name: "sakuracloud_archive",
        image: "archive.png",
        order: 2,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "size",
                order: 10,
                ignore: false,
                allowValues: null,
                singleSelect: true,
                suffix: "GiB",
            },
            {
                name: "archive_file",
                order: 20,
                ignore: false,
            },
            {
                name: "hash",
                order: 21,
                ignore: false,
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
    },
    {
        name: "sakuracloud_archive_share",
        image: "archive.png",
        order: 3,
        ignore: false,
        arguments: [
            {
                name: "archive_id",
                order: 10,
                ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_archive\..*\.id/],
            },
        ],
    },
    {
        name: "sakuracloud_cdrom",
        image: "disc.png",
        order: 4,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "size",
                order: 10,
                ignore: false,
                allowValues: null,
                singleSelect: true,
                suffix: "GiB",
            },
            {
                name: "iso_image_file",
                order: 21,
                ignore: false,
            },
            {
                name: "content",
                order: 22,
                ignore: false,
            },
            {
                name: "content_file_name",
                order: 23,
                ignore: false,
            },
            {
                name: "hash",
                order: 24,
                ignore: false,
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
    },
    // network
    {
        name: "sakuracloud_switch",
        image: "switch.png",
        order: 10,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "bridge_id",
                order: 10,
                ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_bridge\..*\.id/],
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
    },
    {
        name: "sakuracloud_internet",
        image: "switch-cloud.png",
        order: 20,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "band_width",
                order: 10,
                ignore: false,
            },
            {
                name: "netmask",
                order: 11,
                ignore: false,
            },
            {
                name: "enable_ipv6",
                order: 12,
                ignore: false,
            },
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
    },
    {name: "sakuracloud_subnet", image: "", order: 21, ignore: true},
    {name: "sakuracloud_ipv4_ptr", image: "", order: 22, ignore: true},
    {
        name: "sakuracloud_packet_filter", image: "firewall.png", order: 30, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "expression",
                order: 10,
                ignore: false,
            },
            commonArguments.description,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "expression",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "http", text: "http"},
                            {value: "https", text: "https"},
                            {value: "tcp", text: "tcp"},
                            {value: "udp", text: "udp"},
                            {value: "icmp", text: "icmp"},
                            {value: "fragment", text: "fragment"},
                            {value: "ip", text: "ip"},
                        ],
                    },
                    {name: "source_network", order: 11, ignore: false},
                    {name: "source_port", order: 12, ignore: false},
                    {name: "destination_network", order: 13, ignore: false},
                    {name: "destination_port", order: 14, ignore: false},
                    {name: "description", order: 15, ignore: false},
                    {name: "allow", order: 16, ignore: false},
                ],
            },
        ],
    },
    {
        name: "sakuracloud_packet_filter_rules", image: "firewall.png", order: 35, ignore: false,
        arguments: [
            {
                name: "packet_filter_id",
                order: 1,
                ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_packet_filter\..*\.id/],
            },
            {
                name: "expression",
                order: 10,
                ignore: false,
            },
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "expression",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "http", text: "http"},
                            {value: "https", text: "https"},
                            {value: "tcp", text: "tcp"},
                            {value: "udp", text: "udp"},
                            {value: "icmp", text: "icmp"},
                            {value: "fragment", text: "fragment"},
                            {value: "ip", text: "ip"},
                        ],
                    },
                    {name: "source_network", order: 11, ignore: false},
                    {name: "source_port", order: 12, ignore: false},
                    {name: "destination_network", order: 13, ignore: false},
                    {name: "destination_port", order: 14, ignore: false},
                    {name: "description", order: 15, ignore: false},
                    {name: "allow", order: 16, ignore: false},
                ],
            },
        ],
    },
    {
        name: "sakuracloud_bridge",
        image: "bridge.png",
        order: 40,
        ignore: false,
        arguments: [
            commonArguments.name,
            commonArguments.description,
            commonArguments.zone,
        ],
    },
    {
        name: "sakuracloud_local_router", image: "", order: 50, ignore: false,
        arguments: [
            commonArguments.name,
            {name: "switch", order: 10, ignore: false},
            {name: "network_interface", order: 11, ignore: false},
            {name: "peer", order: 20, ignore: false},
            {name: "static_route", order: 21, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "switch",
                arguments: [
                    {name: "category", order: 10, ignore: false},
                    {name: "code", order: 11, ignore: false},
                    {name: "zone_id", order: 12, ignore: false},
                ],
            },
            {
                name: "network_interface",
                arguments: [
                    {name: "vip", order: 10, ignore: false},
                    {name: "ip_addresses", order: 11, ignore: false},
                    {name: "netmask", order: 12, ignore: false},
                    {name: "vrid", order: 13, ignore: false},
                ],
            },
            {
                name: "peer",
                arguments: [
                    {name: "peer_id", order: 10, ignore: false},
                    {name: "secret_key", order: 11, ignore: false},
                    {name: "enabled", order: 12, ignore: false},
                    {name: "description", order: 13, ignore: false},
                ],
            },
            {
                name: "static_route",
                arguments: [
                    {name: "prefix", order: 10, ignore: false},
                    {name: "next_hop", order: 11, ignore: false},
                ],
            },
        ],
    },
    // appliance
    {
        name: "sakuracloud_auto_backup",
        image: "archive-cloud.png",
        order: 1,
        ignore: false,
        arguments: [
            commonArguments.name,
            {name: "disk_id", order: 10, ignore: false,
                referenceSourcePatterns: [
                    /^(data\.)?sakuracloud_disk\..*\.id/,
                ],
            },
            commonArguments.weekdays(11),
            {name: "max_backup_num", order: 12, ignore: false},
            commonArguments.description,
            commonArguments.iconId,
            commonArguments.tags,
        ],
    },
    {
        name: "sakuracloud_database", image: "db.png", order: 2, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "database_type", order: 10, ignore: false,
                allowValues: [
                    {value: "postgresql", text: "PostgreSQL"},
                    {value: "mariadb", text: "MariaDB"},
                ],
            },
            {
                name: "database_version", order: 11, ignore: false,
            },
            {
                name: "plan", order: 12, ignore: false,
                allowValues: [
                    {value: "10g", text: "10GB"},
                    {value: "30g", text: "30GB"},
                    {value: "90g", text: "90GB"},
                    {value: "240g", text: "240GB"},
                    {value: "500g", text: "500GB"},
                    {value: "1t", text: "1TB"},
                ],
            },
            {name: "network_interface", order: 13, ignore: false},
            {name: "username", order: 20, ignore: false},
            {name: "password", order: 21, ignore: false},
            {name: "replica_user", order: 22, ignore: false},
            {name: "replica_password", order: 23, ignore: false},
            {name: "backup", order: 30, ignore: false},
            commonArguments.tags,
            commonArguments.description,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "network_interface",
                arguments: [
                    {
                        name: "switch_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "ip_address", order: 11, ignore: false},
                    {name: "masklen", order: 12, ignore: false},
                    {name: "gateway", order: 13, ignore: false},
                    {name: "port", order: 14, ignore: false},
                    {name: "source_range", order: 15, ignore: false},
                ],
            },
            {
                name: "backup",
                arguments: [
                    {name: "time", order: 10, ignore: false},
                    commonArguments.weekdays(20),
                ],
            },
        ],
    },
    {
        name: "sakuracloud_database_read_replica", image: "db.png", order: 3, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "master_id", order: 10, ignore: false,
                referenceSourcePatterns: [
                    /^(data\.)?sakuracloud_database\..*\.id/,
                ],
            },
            {name: "network_interface", order: 12, ignore: false},
            commonArguments.tags,
            commonArguments.description,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "network_interface",
                arguments: [
                    {
                        name: "switch_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "ip_address", order: 11, ignore: false},
                    {name: "masklen", order: 12, ignore: false},
                    {name: "gateway", order: 13, ignore: false},
                    {name: "source_range", order: 15, ignore: false},
                ],
            },
        ],
    },
    {
        name: "sakuracloud_load_balancer", image: "loadbalancer.png", order: 4, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "plan", order: 11, ignore: false,
                allowValues: [
                    {value: "standard", text: "スタンダード"},
                    {value: "highspec", text: "ハイスペック"},
                ],
            },
            {name: "network_interface", order: 20, ignore: false},
            {name: "vip", order: 30, ignore: false},
            commonArguments.tags,
            commonArguments.description,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "network_interface",
                arguments: [
                    {
                        name: "switch_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "vrid", order: 11, ignore: false},
                    {name: "ip_addresses", order: 20, ignore: false},
                    {name: "masklen", order: 21, ignore: false},
                    {name: "gateway", order: 22, ignore: false},
                ],
            },
            {
                name: "vip",
                arguments: [
                    {name: "vip", order: 10, ignore: false},
                    {name: "port", order: 11, ignore: false},
                    {name: "delay_loop", order: 12, ignore: false},
                    {name: "sorry_server", order: 13, ignore: false},
                    {name: "description", order: 14, ignore: false},
                    {name: "server", order: 15, ignore: false},
                ],
            },
            {
                name: "server",
                arguments: [
                    {name: "ip_address", order: 10, ignore: false},
                    {
                        name: "protocol", order: 11, ignore: false,
                        allowValues: [
                            {value: "http", text: "HTTP"},
                            {value: "https", text: "HTTPS"},
                            {value: "tcp", text: "TCP"},
                            {value: "ping", text: "Ping"},
                        ],
                    },
                    {name: "path", order: 12, ignore: false},
                    {name: "status", order: 13, ignore: false},
                    {name: "enabled", order: 14, ignore: false},
                ],
            },
        ],
    },
    {
        name: "sakuracloud_vpc_router", image: "", order: 5, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "plan", order: 10, ignore: false,
                allowValues: [
                    {value: "standard", text: "スタンダード"},
                    {value: "premium", text: "プレミアム"},
                    {value: "highspec", text: "ハイスペック(1,600Mbps)"},
                    {value: "highspec4000", text: "ハイスペック(4,000Mbps)"},
                ],
            },
            {name: "version", order:15, ignore:false},
            {name: "public_network_interface", order: 20, ignore: false},
            {name: "private_network_interface", order: 21, ignore: false},
            {name: "internet_connection", order: 22, ignore: false},
            {name: "syslog_host", order: 23, ignore: false},

            {name: "dhcp_server", order: 31, ignore: false},
            {name: "dhcp_static_mapping", order: 32, ignore: false},
            {name: "static_nat", order: 33, ignore: false},
            {name: "port_forwarding", order: 34, ignore: false},
            {name: "firewall", order: 40, ignore: false},
            {name: "l2tp", order: 50, ignore: false},
            {name: "pptp", order: 51, ignore: false},
            {name: "user", order: 52, ignore: false},
            {name: "site_to_site_vpn", order: 60, ignore: false},
            {name: "static_route", order: 70, ignore: false},

            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "public_network_interface",
                arguments: [
                    {
                        name: "switch_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "vrid", order: 11, ignore: false},
                    {name: "vip", order: 12, ignore: false},
                    {name: "ip_addresses", order: 13, ignore: false},
                    {name: "aliases", order: 14, ignore: false},
                ],
            },
            {
                name: "private_network_interface",
                arguments: [
                    {name: "index", order: 10, ignore: false},
                    {
                        name: "switch_id", order: 11, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "vip", order: 12, ignore: false},
                    {name: "ip_addresses", order: 13, ignore: false},
                    {name: "netmask", order: 14, ignore: false},
                ],
            },
            {
                name: "dhcp_server",
                arguments: [
                    {name: "interface_index", order: 10, ignore: false},
                    {name: "range_start", order: 11, ignore: false},
                    {name: "range_stop", order: 12, ignore: false},
                    {name: "dns_servers", order: 13, ignore: false},
                ],
            },
            {
                name: "dhcp_static_mapping",
                arguments: [
                    {name: "ip_address", order: 10, ignore: false},
                    {name: "mac_address", order: 11, ignore: false},
                ],
            },
            {
                name: "static_nat",
                arguments: [
                    {name: "public_ip", order: 10, ignore: false},
                    {name: "private_ip", order: 11, ignore: false},
                    {name: "description", order: 12, ignore: false},
                ],
            },
            {
                name: "port_forwarding",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "tcp", text: "tcp"},
                            {value: "udp", text: "udp"},
                        ],
                    },
                    {name: "public_port", order: 11, ignore: false},
                    {name: "private_ip", order: 12, ignore: false},
                    {name: "private_port", order: 13, ignore: false},
                    {name: "description", order: 14, ignore: false},
                ],
            },
            {
                name: "firewall",
                arguments: [
                    {name: "interface_index", order: 10, ignore: false},
                    {
                        name: "direction", order: 11, ignore: false,
                        allowValues: [
                            {value: "send", text: "send"},
                            {value: "receive", text: "receive"},
                        ],
                    },
                    {name: "expression", order: 12, ignore: false},
                ],
            },
            {
                name: "l2tp",
                arguments: [
                    {name: "pre_shared_secret", order: 10, ignore: false},
                    {name: "range_start", order: 11, ignore: false},
                    {name: "range_stop", order: 12, ignore: false},
                ],
            },
            {
                name: "pptp",
                arguments: [
                    {name: "range_start", order: 11, ignore: false},
                    {name: "range_stop", order: 12, ignore: false},
                ],
            },
            {
                name: "user",
                arguments: [
                    {name: "name", order: 10, ignore: false},
                    {name: "password", order: 11, ignore: false},
                ],
            },
            {
                name: "site_to_site_vpn",
                arguments: [
                    {name: "peer", order: 11, ignore: false},
                    {name: "remote_id", order: 12, ignore: false},
                    {name: "pre_shared_secret", order: 13, ignore: false},
                    {name: "routes", order: 14, ignore: false},
                    {name: "local_prefix", order: 15, ignore: false},
                ],
            },
            {
                name: "static_route",
                arguments: [
                    {name: "prefix", order: 10, ignore: false},
                    {name: "next_hop", order: 11, ignore: false},
                ],
            },
            {
                name: "expression",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "tcp", text: "tcp"},
                            {value: "udp", text: "udp"},
                            {value: "icmp", text: "icmp"},
                            {value: "ip", text: "ip"},
                        ],
                    },
                    {name: "source_network", order: 11, ignore: false},
                    {name: "source_port", order: 12, ignore: false},
                    {name: "destination_network", order: 13, ignore: false},
                    {name: "destination_port", order: 14, ignore: false},
                    {name: "allow", order: 15, ignore: false},
                    {name: "logging", order: 16, ignore: false},
                    {name: "description", order: 17, ignore: false},
                ],
            },
        ],
    },
    {
        name: "sakuracloud_nfs", image: "", order: 6, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "plan", order: 10, ignore: false,
                allowValues: [
                    {value: "hdd", text: "HDD"},
                    {value: "ssd", text: "SSD"},
                ],
            },
            {name: "size", order: 11, ignore: false},
            {name: "network_interface", order: 12, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "network_interface",
                arguments: [
                    {
                        name: "switch_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "ip_address", order: 20, ignore: false},
                    {name: "masklen", order: 21, ignore: false},
                    {name: "gateway", order: 22, ignore: false},
                ],
            },
        ],
    },

    // global
    {
        name: "sakuracloud_proxylb", image: "", order: 10, ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "plan", order: 10, ignore: false,
                allowValues: [
                    {value: "100", text: "100CPS"},
                    {value: "500", text: "500CPS"},
                    {value: "1000", text: "1,000CPS"},
                    {value: "5000", text: "5,000CPS"},
                    {value: "10000", text: "10,000CPS"},
                    {value: "50000", text: "50,000CPS"},
                    {value: "100000", text: "100,000CPS"},
                ],
            },
            {name: "vip_failover", order: 11, ignore: false},
            {name: "sticky_session", order: 12, ignore: false},
            {name: "timeout", order: 13, ignore: false},
            {
                name: "region", order: 20, ignore: false,
                allowValues: [
                    {value: "is1", text: "石狩(is1)"},
                    {value: "tk1", text: "東京(tk1)"},
                    {value: "anycast", text: "エニーキャスト"},
                ],
            },
            {name: "health_check", order: 20, ignore: false},
            {name: "sorry_server", order: 21, ignore: false},
            {name: "bind_port", order: 30, ignore: false},
            {name: "certificate", order: 40, ignore: false},
            {name: "server", order: 50, ignore: false},
            {name: "rule", order: 51, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "bind_port",
                arguments: [
                    {
                        name: "proxy_mode", order: 10, ignore: false,
                        allowValues: [
                            {value: "http", text: "http"},
                            {value: "https", text: "https"},
                            {value: "tcp", text: "tcp"},
                        ],
                    },
                    {name: "redirect_to_https", order: 11, ignore: false},
                    {name: "support_http2", order: 12, ignore: false},
                    {name: "response_header", order: 13, ignore: false},
                    {name: "port", order: 14, ignore: false},
                ],
            },
            {
                name: "response_header",
                arguments: [
                    {name: "header", order: 10, ignore: false},
                    {name: "value", order: 11, ignore: false},
                ],
            },
            {
                name: "health_check",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "http", text: "http"},
                            {value: "tcp", text: "tcp"},
                        ],
                    },
                    {name: "delay_loop", order: 11, ignore: false},
                    {name: "host_header", order: 12, ignore: false},
                    {name: "path", order: 13, ignore: false},
                    {name: "port", order: 14, ignore: false},
                ],
            },
            {
                name: "sorry_server",
                arguments: [
                    {name: "ip_address", order: 10, ignore: false},
                    {name: "port", order: 11, ignore: false},
                ],
            },
            {
                name: "certificate",
                arguments: [
                    {name: "server_cert", order: 10, ignore: false},
                    {name: "intermediate_cert", order: 11, ignore: false},
                    {name: "private_key", order: 12, ignore: false},
                    {name: "additional_certificate", order: 20, ignore: false},
                ],
            },
            {
                name: "additional_certificate",
                arguments: [
                    {name: "server_cert", order: 10, ignore: false},
                    {name: "intermediate_cert", order: 11, ignore: false},
                    {name: "private_key", order: 12, ignore: false},
                ],
            },
            {
                name: "server",
                arguments: [
                    {name: "ip_address", order: 10, ignore: false},
                    {name: "port", order: 11, ignore: false},
                    {name: "group", order: 12, ignore: false},
                    {name: "enabled", order: 13, ignore: false},
                ],
            },
            {
                name: "rule",
                arguments: [
                    {name: "host", order: 10, ignore: false},
                    {name: "path", order: 11, ignore: false},
                    {name: "group", order: 12, ignore: false},
                ],
            },
        ],
    },
    {
        name: "sakuracloud_proxylb_acme", image: "", order: 11, ignore: false,
        arguments: [
            {
                name: "proxylb_id", order: 10, ignore: false,
                referenceSourcePatterns: [
                    /^(data\.)?sakuracloud_proxylb\..*\.id/
                ],
            },
            {name: "accept_tos", order: 11, ignore: false},
            {name: "common_name", order: 12, ignore: false},
            {name: "update_delay_sec", order: 13, ignore: false},
        ],
    },
    {
        name: "sakuracloud_gslb", image: "", order: 20, ignore: false,
        arguments: [
            commonArguments.name,
            {name: "health_check", order: 10, ignore: false},
            {name: "weighted", order: 10, ignore: false},
            {name: "sorry_server", order: 10, ignore: false},
            {name: "server", order: 10, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "health_check",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "http", text: "http"},
                            {value: "https", text: "https"},
                            {value: "tcp", text: "tcp"},
                            {value: "ping", text: "ping"},
                        ],
                    },
                    {name: "delay_loop", order: 11, ignore: false},
                    {name: "host_header", order: 12, ignore: false},
                    {name: "path", order: 13, ignore: false},
                    {name: "port", order: 14, ignore: false},
                ],
            },
            {
                name: "server",
                arguments: [
                    {name: "ip_address", order: 10, ignore: false},
                    {name: "weight", order: 11, ignore: false},
                    {name: "enabled", order: 12, ignore: false},
                ],
            }
        ],
    },
    {
        name: "sakuracloud_dns", image: "", order: 30, ignore: false,
        arguments: [
            {name: "zone", order: 10, ignore: false},
            {name: "record", order: 11, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "record",
                arguments: [
                    {name: "name", order: 10, ignore: false},
                    {
                        name: "type", order: 11, ignore: false,
                        allowValues: [
                            {value: "A", text: "A"},
                            {value: "AAAA", text: "AAAA"},
                            {value: "ALIAS", text: "ALIAS"},
                            {value: "CAA", text: "CAA"},
                            {value: "CNAME", text: "CNAME"},
                            {value: "NS", text: "NS"},
                            {value: "MX", text: "MX"},
                            {value: "PTR", text: "PTR"},
                            {value: "TXT", text: "TXT"},
                            {value: "SRV", text: "SRV"},
                        ],
                    },
                    {name: "value", order: 12, ignore: false},
                    {name: "ttl", order: 13, ignore: false},
                    {name: "priority", order: 14, ignore: false},
                    {name: "weight", order: 15, ignore: false},
                    {name: "port", order: 16, ignore: false},
                ],
            }
        ],
    },
    {
        name: "sakuracloud_dns_record", image: "", order: 31, ignore: false,
        arguments: [
            {
                name: "dns_id", order: 1, ignore: false,
                referenceSourcePatterns: [
                    /^(data\.)?sakuracloud_dns\..*\.id/
                ],
            },
            {name: "name", order: 10, ignore: false},
            {
                name: "type", order: 11, ignore: false,
                allowValues: [
                    {value: "A", text: "A"},
                    {value: "AAAA", text: "AAAA"},
                    {value: "ALIAS", text: "ALIAS"},
                    {value: "CAA", text: "CAA"},
                    {value: "CNAME", text: "CNAME"},
                    {value: "NS", text: "NS"},
                    {value: "MX", text: "MX"},
                    {value: "PTR", text: "PTR"},
                    {value: "TXT", text: "TXT"},
                    {value: "SRV", text: "SRV"},
                ],
            },
            {name: "value", order: 12, ignore: false},
            {name: "ttl", order: 13, ignore: false},
            {name: "priority", order: 14, ignore: false},
            {name: "weight", order: 15, ignore: false},
            {name: "port", order: 16, ignore: false},
        ],
    },
    {
        name: "sakuracloud_simple_monitor", image: "", order: 40, ignore: false,
        arguments: [
            {name: "target", order: 10, ignore: false},
            {name: "delay_loop", order: 11, ignore: false},
            {name: "health_check", order: 12, ignore: false},
            {name: "notify_email_enabled", order: 13, ignore: false},
            {name: "notify_email_html", order: 14, ignore: false},
            {name: "notify_slack_enabled", order: 15, ignore: false},
            {name: "notify_slack_webhook", order: 16, ignore: false},
            {name: "notify_interval", order: 17, ignore: false},
            {name: "enabled", order: 18, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "health_check",
                arguments: [
                    {
                        name: "protocol", order: 10, ignore: false,
                        allowValues: [
                            {value: "http", text: "http"},
                            {value: "https", text: "https"},
                            {value: "ping", text: "ping"},
                            {value: "tcp", text: "tcp"},
                            {value: "dns", text: "dns"},
                            {value: "ssh", text: "ssh"},
                            {value: "smtp", text: "smtp"},
                            {value: "pop3", text: "pop3"},
                            {value: "snmp", text: "snmp"},
                            {value: "sslcertificate", text: "証明書有効期限(sslcertificate)"},
                        ],
                    },
                    {name: "delay_loop", order: 11, ignore: false},
                    {name: "host_header", order: 12, ignore: false},
                    {name: "path", order: 13, ignore: false},
                    {name: "status", order: 14, ignore: false},
                    {name: "sni", order: 15, ignore: false},
                    {name: "username", order: 20, ignore: false},
                    {name: "password", order: 21, ignore: false},
                    {name: "port", order: 30, ignore: false},
                    {name: "qname", order: 40, ignore: false},
                    {name: "excepcted_data", order: 41, ignore: false},
                    {name: "community", order: 42, ignore: false},
                    {name: "snmp_version", order: 43, ignore: false},
                    {name: "oid", order: 44, ignore: false},
                    {name: "remaining_days", order: 50, ignore: false},
                ],
            },
        ],
    },

    // secure mobile
    {
        name: "sakuracloud_sim", image: "", order: 1, ignore: false,
        arguments: [
            commonArguments.name,
            {name: "iccid", order: 10, ignore: false},
            {name: "passcode", order: 11, ignore: false},
            {name: "imei", order: 12, ignore: false},
            {
                name: "carrier",
                order: 20,
                ignore: false,
                allowValues: [
                    {value: "docomo", text: "NTT DOCOMO"},
                    {value: "kddi", text: "KDDI"},
                    {value: "softbank", text: "SoftBank"},
                ],
                singleSelect: false,
            },
            {name: "enabled", order: 30, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
    },
    {
        name: "sakuracloud_mobile_gateway", image: "", order: 2, ignore: false,
        arguments: [
            commonArguments.name,
            {name: "private_network_interface", order: 10, ignore: false},
            {name: "internet_connection", order: 10, ignore: false},
            {name: "inter_device_communication", order: 10, ignore: false},
            {name: "dns_servers", order: 10, ignore: false},
            {name: "traffic_control", order: 10, ignore: false},
            {name: "static_route", order: 10, ignore: false},
            {name: "sim", order: 10, ignore: false},
            {name: "sim_route", order: 10, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
            commonArguments.zone,
        ],
        argumentBlocks: [
            {
                name: "private_network_interface",
                arguments: [
                    {
                        name: "switch_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_switch\..*\.id/,
                            /^(data\.)?sakuracloud_internet\..*\.switch_id/
                        ],
                    },
                    {name: "ip_address", order: 20, ignore: false},
                    {name: "netmask", order: 21, ignore: false},
                ],
            },
            {
                name: "traffic_control",
                arguments: [
                    {name: "quota", order: 10, ignore: false},
                    {name: "band_width_limit", order: 11, ignore: false},
                    {name: "auto_traffic_shaping", order: 12, ignore: false},
                    {name: "enable_email", order: 20, ignore: false},
                    {name: "enable_slack", order: 21, ignore: false},
                    {name: "slack_webhook", order: 22, ignore: false},
                ],
            },
            {
                name: "static_route",
                arguments: [
                    {name: "prefix", order: 10, ignore: false},
                    {name: "next_hop", order: 11, ignore: false},
                ],
            },
            {
                name: "sim",
                arguments: [
                    {
                        name: "sim_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_sim\..*\.id/,
                        ],
                    },
                    {name: "ip_address", order: 11, ignore: false},
                ],
            },
            {
                name: "sim_route",
                arguments: [
                    {
                        name: "sim_id", order: 10, ignore: false,
                        referenceSourcePatterns: [
                            /^(data\.)?sakuracloud_sim\..*\.id/,
                        ],
                    },
                    {name: "prefix", order: 11, ignore: false},
                ],
            },
        ],
    },

    // sms
    {
        name: "sakuracloud_esme",
        image: "",
        order: 0,
        ignore: false,
        arguments: [
            commonArguments.name,
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
        ],
    },
    // lab
    {
        name: "sakuracloud_certificate_authority",
        image: "",
        order: 0,
        ignore: false,
        arguments: [
            commonArguments.name,
            {name: "subject", order: 10, ignore: false},
            {name: "validity_period_hours", order: 11, ignore: false},
            {name: "client", order: 20, ignore: false},
            {name: "server", order: 30, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "subject",
                arguments: [
                    {name: "country",order:10, ignore: false},
                    {name: "organization",order:20, ignore: false},
                    {name: "organization_units",order:21, ignore: false},
                    {name: "common_name",order:30, ignore: false},
                ],
            },
            {
                name: "client",
                arguments: [
                    {name: "subject", order: 10, ignore: false},
                    {name: "email", order: 21, ignore: false},
                    {name: "csr", order: 22, ignore: false},
                    {name: "public_key", order: 23, ignore: false},
                    {name: "validity_period_hours", order: 30, ignore: false},
                    {name: "hold", order: 40, ignore: false},
                ],
            },
            {
                name: "server",
                arguments: [
                    {name: "subject", order: 10, ignore: false},
                    {name: "csr", order: 22, ignore: false},
                    {name: "public_key", order: 23, ignore: false},
                    {name: "subject_alternative_names", order: 25, ignore: false},
                    {name: "validity_period_hours", order: 30, ignore: false},
                    {name: "hold", order: 40, ignore: false},
                ],
            }
        ],
    },
    {
        name: "sakuracloud_container_registry",
        image: "",
        order: 0,
        ignore: false,
        arguments: [
            commonArguments.name,
            {
                name: "access_level",
                order: 10,
                ignore: false,
                allowValues: [
                    {value: "readwrite", text: "Push&Pull"},
                    {value: "readonly", text: "Pullのみ"},
                    {value: "none", text: "非公開"},
                ],
            },
            {
                name: "virtual_domain",
                order: 11,
                ignore: false,
            },
            {
                name: "subdomain_label",
                order: 12,
                ignore: false,
                suffix: ".sakuracr.jp",
            },
            {name: "user", order: 20, ignore: false},
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ],
        argumentBlocks: [
            {
                name: "user",
                arguments: [
                    {name: "name", order: 10, ignore: false},
                    {name: "password", order: 11, ignore: false},
                    {
                        name: "permission", order: 12, ignore: false,
                        allowValues: [
                            {value: "all", text: "All"},
                            {value: "readwrite", text: "Push&Pull"},
                            {value: "readonly", text: "Pullのみ"},
                        ],
                    },
                ],
            }
        ],
    },

    {
        name: "sakuracloud_enhanced_db",
        image: "",
        order: 0,
        ignore: false,
        arguments: [
            commonArguments.name,
            commonArguments.description,
            commonArguments.tags,
            commonArguments.iconId,
        ]
    },
    // misc
    {
        name: "sakuracloud_icon", image: "", order: 0, ignore: false,
        arguments: [
            commonArguments.name,
            {name: "source", order: 10, ignore: false},
            {name: "base64content", order: 11, ignore: false},
            commonArguments.tags,
        ],
    },
    {
        name: "sakuracloud_note", image: "", order: 1, ignore: false,
        arguments: [
            commonArguments.name,
            {name: "content", order: 10, ignore: false},
            {
                name: "class", order: 11, ignore: false,
                allowValues: [
                    {value: "shell", text: "shell"},
                    {value: "yaml_cloud_config", text: "yaml_cloud_config"},
                ],
            },
            commonArguments.tags,
            commonArguments.iconId,
        ],
    },
    {
        name: "sakuracloud_ssh_key", image: "", order: 2, ignore: false,
        arguments: [
            commonArguments.name,
            commonArguments.description,
            {name: "public_key", order: 10, ignore: false},
        ],
    },
    {
        name: "sakuracloud_ssh_key_gen", image: "", order: 3, ignore: false,
        arguments: [
            commonArguments.name,
            commonArguments.description,
            {name: "pass_phrase", order: 10, ignore: false},
        ],
    },

    {name: "sakuracloud_bucket_object", image: "", order: 0, ignore: true},

    {name: "sakuracloud_webaccel_certificate", image:"", order:0, ignore:false},

    {name: "sakuracloud_apprun_application", image:"", order:0, ignore:false}
];

const filterArgumentBlock = {
    name: "filter",
    arguments:[
        {name: "id", order: 10, ignore: false},
        {name: "names", order: 20, ignore: false},
        {name: "tags", order: 30, ignore: false},
        {name: "condition", order: 40, ignore: false},
    ],
}

export const dataSourceDef: resourceDef[] = [
    // compute
    {
        name: "sakuracloud_server", image: "server-cloud.png", order: 1, ignore: false,
        arguments: [
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_server_vnc_info", image: "server-cloud.png", order: 2, ignore: false,
        arguments:[
            {
                name: "server_id", order: 10, ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_server\..*\.id/,],
            },
            commonArguments.zone,
        ],
    },
    {
        name: "sakuracloud_private_host", image: "servers.png", order: 3, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_auto_scale", image: "servers.png", order: 4, ignore: false,
        arguments:[
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    // storage
    {
        name: "sakuracloud_disk", image: "HDD.png", order: 1, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_cdrom", image: "disc.png", order: 3, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_archive", image: "archive.png", order: 2, ignore: false,
        arguments: [
            {
                name: "os_type", order: 10, ignore: false,
                allowValues: [
                    {value: "centos", text: "CentOS"},
                    {value: "centos7", text: "CentOS7"},
                    {value: "almalinux", text: "Alma Linux"},
                    {value: "almalinux9", text: "Alma Linux9"},
                    {value: "almalinux8", text: "Alma Linux8"},
                    {value: "rockylinux", text: "Rocky Linux"},
                    {value: "rockylinux9", text: "Rocky Linux9"},
                    {value: "rockylinux8", text: "Rocky Linux8"},
                    {value: "miraclelinux", text: "MIRACLE LINUX"},
                    {value: "miraclelinux8", text: "MIRACLE LINUX8"},
                    {value: "ubuntu", text: "Ubuntu"},
                    {value: "ubuntu2204", text: "Ubuntu2204"},
                    {value: "ubuntu2004", text: "Ubuntu2004"},
                    {value: "ubuntu2004", text: "Ubuntu2004"},
                    {value: "ubuntu1804", text: "Ubuntu1804"},
                    {value: "debian", text: "Debian"},
                    {value: "debian11", text: "Debian11"},
                    {value: "debian10", text: "Debian10"},
                    {value: "kusanagi", text: "Kusanagi"},
                ],
            },
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },

    // network
    {
        name: "sakuracloud_switch", image: "switch.png", order: 1, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_internet", image: "switch-cloud.png", order: 2, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_subnet", image: "", order: 3, ignore: false,
        arguments:[
            {
                name: "internet_id", order: 10, ignore: false,
                referenceSourcePatterns: [/^(data\.)?sakuracloud_internet\..*\.id/,],
            },
            {name: "index", order: 20, ignore: false},
            commonArguments.zone,
        ],
    },
    {
        name: "sakuracloud_packet_filter", image: "firewall.png", order: 10, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_bridge", image: "bridge.png", order: 20, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {name: "sakuracloud_local_router", image: "", order: 30, ignore: false},

    // appliance
    {
        name: "sakuracloud_database", image: "db.png", order: 10, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_load_balancer", image: "loadbalancer.png", order: 20, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_vpc_router", image: "", order: 30, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_nfs", image: "", order: 40, ignore: false,
        arguments:[
            commonArguments.zone,
        ],
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },

    // global
    {
        name: "sakuracloud_proxylb", image: "", order: 10, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_gslb", image: "", order: 20, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_dns", image: "", order: 30, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_simple_monitor", image: "", order: 40, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },

    // sms
    {
        name: "sakuracloud_esme", image: "", order: 0, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },

    // lab
    {
        name: "sakuracloud_certificate_authority", image: "", order: 0, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_container_registry", image: "", order: 0, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_enhanced_db", image: "", order: 0, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },

    // misc
    {
        name: "sakuracloud_icon", image: "", order: 10, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_note", image: "", order: 20, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {
        name: "sakuracloud_ssh_key", image: "", order: 30, ignore: false,
        argumentBlocks: [
            filterArgumentBlock,
        ],
    },
    {name: "sakuracloud_zone", image: "", order: 40, ignore: false},

    {name: "sakuracloud_bucket_object", image: "", order: 0, ignore: false},
    {name: "sakuracloud_webaccel", image:"", order:0, ignore:false},
    {name: "sakuracloud_apprun_application", image:"", order:0, ignore:false}
]
